import React from 'react';
import { Grid } from '@mui/material';
import { Address, Payments, Observations, StatusOrder, CustomerOrder } from "./detailOrderElements"
import { isDeliveryOrder } from '../../../util/order/orderUtil';
import ListItemsGrouped from '../../layout-components/ListItemsGrouped';
import { useSelector } from 'react-redux';

export default function DetailOrderBody({ card }) {
    

    const order = card.data.iFoodOrder;
    const isDelivery = isDeliveryOrder(card.data);
    //const groupedItems = useSelector(state => state.detailOrder.groupedItems)

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1} >

            {order && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <StatusOrder order={card.data} />
                    </Grid>

                    <Grid item xs={12}>
                        {order.customer && (
                            <CustomerOrder customer={order?.customer} complementoIdentificador={card.data.complementoIdentificador} />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {order.deliveryAddress && isDelivery && order?.deliveryMethod?.mode !== 'INDOOR' &&
                            <Address card={card} />}
                    </Grid>

                    {/*
                    <Grid item xs={12}>
                        <ListItemsGrouped groupedItems={groupedItems}/>

                    </Grid>

                    <Grid item xs={12}>
                        {order.payments && <Payments payments={order.payments} />}
                    </Grid>

                    <Grid item xs={12}>
                        <Observations order={order} />
                    </Grid>
                */}
                </React.Fragment>
            )}


        </Grid>
    )
}

export function DetailItensAndPayments({ card }) {
    //const dispatch = useDispatch()

    const order = card.data.iFoodOrder;
    //const isDelivery = isDeliveryOrder(card.data);
    const groupedItems = useSelector(state => state.detailOrder.groupedItems)

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1} >

            {order && (
                <React.Fragment>

                    <Grid item xs={12}>
                        <ListItemsGrouped groupedItems={groupedItems} />
                    </Grid>

                    <Grid item xs={12}>
                        {order.payments && <Payments payments={order.payments} />}
                    </Grid>

                    <Grid item xs={12}>
                        <Observations order={order} />
                    </Grid>

                </React.Fragment>
            )}


        </Grid>
    )
}


