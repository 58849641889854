import React, { memo, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { LoadingButton } from '@mui/lab'
import { Chip, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import { extractErrorMessage } from '../../util/http/HttpUtil'
import AuthServiceTransient from '../../service/AuthServiceTransient'
import { ThemeProvider } from '@emotion/react'
import { createThemeApp } from '../../theme'
import { dateFormat } from '../../util/formatter'

const ROWS_PER_PAGE = 6
const PREFIX = 'JobAssincrono'

const classes = {
    ready: `${PREFIX}-ready`,
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.ready}`]: {
        backgroundColor: '#66bb6a1c',
    },
}))


function useSocket() {
    const [data, setData] = useState()
    const [socket, setSocket] = useState(null)
    const [room, setRoom] = useState('')

    useEffect(() => {
        function initSocket() {
            if (socket || !room) return

            const room_socket = room + process.env.REACT_APP_SOCKET_ROOM
            const query = 'room=' + room_socket + '&auth=' + process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
            const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
                query,
                transports: ['websocket'],
            })

            console.log('Socket instanciado', webSocket)

            webSocket.on("connect", () => {
                console.log('connect SocketIO', webSocket.id)
            })

            webSocket.on("disconnect", (msg) => {
                console.log('disconnect SocketIO', webSocket.id)
                console.log("Disconnection message", msg)
            })

            setSocket(webSocket)
        }

        initSocket()

        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
    }, [room])

    useEffect(() => {
        if (!socket) return

        function onMessageReceive(event) {
            if (event.type === 'UPDATE_STATUS_JOB') {
                setData(event.data)
            }
        }

        socket.room = room

        // Recebe um update de socket de outros usuários socket
        socket.on('Update', (event) => onMessageReceive(event))

        // Recebe um update do socket do servidor
        socket.on('reciveSocketMessage', (event) => onMessageReceive(event))
    }, [socket])

    return [data, setRoom]
}

function BodyWrapper({ children }) {
    const theme = createThemeApp('light')

    return (
        <ThemeProvider theme={theme}>
            <style jsx="true" global="true">
                {`body {
                    margin: 0;
                }`}
            </style>
            {children}
        </ThemeProvider>
    )
}

const StatusIcon = memo(function StatusIcon({ status }) {
    if (status === 'PENDENTE') {
        return <CircleIcon fontSize="small" color="warning" />
    }

    if (status === 'INICIADO') {
        return <CircleIcon fontSize="small" color="info" />
    }

    if (status === 'FINALIZADO') {
        return <CircleIcon fontSize="small" color="success" />
    }

    if (status === 'ERRO') {
        return <CircleIcon fontSize="small" color="error" />
    }

    return <CircleIcon fontSize="small" color="default" />
})

const StatusChip = memo(function StatusChip({ status }) {
    if (status === 'PENDENTE') {
        return <Chip variant="outlined" label={status} color="warning" />
    }

    if (status === 'INICIADO') {
        return <Chip variant="outlined" label={status} color="info" />
    }

    if (status === 'FINALIZADO') {
        return <Chip variant="outlined" label={status} color="success" />
    }

    if (status === 'ERRO') {
        return <Chip variant="outlined" label={status} color="error" />
    }

    return <Chip variant="outlined" label={status} color="default" />
})

function JobAssincronoRow({ row, jobs, setJobs }) {
    const { enqueueSnackbar } = useSnackbar()
    const [isOpening, setIsOpening] = useState(false)

    function abrir(job) {
        setIsOpening(true)

        const copyJob = Object.assign({}, job)

        copyJob.visualizado = true

        AuthServiceTransient.post('/api-v2/jobAssincrono', copyJob).then(rest => {
            const copyJobs = [...jobs].map(item => item.id === rest.id ? rest : item)

            setJobs(copyJobs)

            AuthServiceTransient.get('/api-v2/jobAssincrono/open/' + rest.id)
                .then(rest => window.open(rest.url, '_blank', 'noreferrer'))
                .finally(() => setIsOpening(false))
        }).catch(err => {
            console.log(err)
            setIsOpening(false)
            extractErrorMessage(err, 'Erro ao abrir relatório').then(msg => {
                enqueueSnackbar('Falha ao abrir: ' + msg, { variant: 'error' })
            })
        })
    }

    return (
        <TableRow
            key={row.uuid}
            className={(!row.visualizado && row.status === 'FINALIZADO') ? classes.ready : ''}>
            <TableCell align="center">
                <StatusIcon status={row.status} />
            </TableCell>
            <TableCell sx={{ width: 300 }}>
                <Typography>
                    {row.descricao}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {dateFormat(row.createdAt, 'dd/MM/yyyy HH:mm')}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <StatusChip status={row.status} />
            </TableCell>
            <TableCell align="right" sx={{ width: 210 }}>
                {row.status === 'ERRO' ? (
                    <Tooltip title={row.dados} disableInteractive arrow>
                        <Chip
                            color="error"
                            variant="outlined"
                            label="O que houve?"
                            icon={<InfoOutlinedIcon color="error" />} />
                    </Tooltip>
                ) : (
                    <LoadingButton
                        variant="outlined"
                        loadingPosition="start"
                        loading={isOpening || row.status === 'INICIADO'}
                        disabled={row.status !== 'FINALIZADO'}
                        startIcon={<OpenInNewIcon />}
                        onClick={() => abrir(row)}>
                        {row.status !== 'FINALIZADO' ? 'Processando' : 'Abrir'}
                    </LoadingButton>
                )}
            </TableCell>
        </TableRow >
    )
}

export default function JobAssincrono(props) {
    const { match: { params } } = props
    const { nome, jwt } = params
    const [jobs, setJobs] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(true)
    const [data, setRoom] = useSocket()
    const [page, setPage] = useState(0)

    useEffect(() => {
        AuthServiceTransient.load(jwt).then((res) => {
            console.log('Dados do usuário/empresa carregados com sucesso.')
            setRoom(res.unidade.uuid)
            loadJobs()
        }).catch(err => {
            console.log(err)
            extractErrorMessage(err, 'Erro ao carregar dados do usuário').then(msg => {
                enqueueSnackbar('Falha ao carregar dados do usuário/empresa carregados: ' + msg, { variant: 'error' })
            })
        })
    }, [])

    const loadJobs = () => {
        setLoading(true)

        AuthServiceTransient.get(`/api-v2/jobAssincrono/list?findBy=nome:${nome}`).then(rest => {
            setJobs(rest)
        }).catch(err => {
            console.log(err)
            extractErrorMessage(err, 'Erro ao carregar dados dos relatórios').then(msg => {
                enqueueSnackbar('Falha ao carregar dados dos relatórios: ' + msg, { variant: 'error' })
            })
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!data) return

        let copyJobs = [...jobs]
        if (!copyJobs.some(item => item.id === data.id)) {
            copyJobs.unshift(data)
            setJobs(copyJobs)
            return
        }

        copyJobs = [...jobs].map(item => item.id === data.id ? data : item)

        setJobs(copyJobs)
    }, [data])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    if (loading) {
        return (
            <BodyWrapper>
                <Box sx={{ p: 3 }}>
                    <LinearProgress />
                </Box>
            </BodyWrapper>
        )
    }

    if (!jobs || !jobs.length) {
        return (
            <BodyWrapper>
                <Box sx={{ p: 3, textAlign: 'center' }}>
                    <Typography>
                        Nenhum dado encontrado
                    </Typography>
                </Box>
            </BodyWrapper>
        )
    }

    const jobsByPage = jobs.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)

    return (
        <BodyWrapper>
            <StyledBox>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer sx={{ minHeight: 498 }}>
                            <Table>
                                <TableHead sx={{ bgcolor: 'grey.100' }}>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        <TableCell>
                                            <Typography>
                                                Descrição
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                Criado em
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobsByPage.map((row) => (
                                        <JobAssincronoRow
                                            key={row.uuid}
                                            row={row}
                                            jobs={jobs}
                                            setJobs={setJobs} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            page={page}
                            component="div"
                            count={jobs.length}
                            rowsPerPage={ROWS_PER_PAGE}
                            rowsPerPageOptions={[ROWS_PER_PAGE]}
                            onPageChange={handleChangePage} />
                    </Grid>
                </Grid>
            </StyledBox>
        </BodyWrapper>
    )
}