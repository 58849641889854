import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Checkbox,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    TableContainer,
    IconButton,
} from '@mui/material';
import { currencyFormat, quantityFormat } from '../../../../../util/formatter';
import { Alert } from '@mui/material';
import { totalItemReal } from '../../../../../util/order/orderUtil';
import { roundUp } from '../../../../../util/genericUtil';
import CircularProgressWithLabel from '../../../elements/CircularProgressWithLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const PREFIX = 'SeparateItemsByCustomer'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    subitem: `${PREFIX}-subitem`,
    paddingLeft: `${PREFIX}-paddingLeft`
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [`&.${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.subitem}`]: {
        opacity: "0.8",
        fontSize: "0.9em"
    },

    [`& .${classes.paddingLeft}`]: {
        paddingLeft: '4px'
    }
}))

export default function SeparateItemsByCustomer({ order, setValueSelected }) {

    const [dividedValue, setDivideValue] = useState(0.0)

    /*Lista de itens que representam a tela, 
    são alimentados pelo useefect quando houver alteração no order*/
    const [items, setItems] = useState([])

    const handleChangeCheckBox = (event, item, index) => {

        let itemsCopy = Object.assign([], items)
        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        const checkedValue = event.target.checked;

        if (itemTemp.partialPayment) {
            itemTemp.partialPayment[index] = checkedValue ? 'CHECKED' : 'NOT_CHECKED'
        }

        const totalItem = totalItemReal(itemTemp)
        const countPays = itemTemp.partialPayment?.filter((pay) => pay === 'CHECKED').length

        itemTemp.pagar = roundUp(((totalItem / itemTemp.divideInto) * countPays), 2)
        itemTemp.pagar = roundUp(itemTemp.pagar + getRestoAPagar(itemTemp), 2)//caso tenha uma diferença para o valor final de até 10 centavos é adicionado no ultimo pagamento

        setItems(itemsCopy)
    }

    useEffect(() => {

        if (order) {
            let itemsTemp = []

            order.iFoodOrder.items.map((item) => {

                if (!item.received) {

                    if (!item.partialPayment) {
                        item.divideInto = 1
                        item.partialPayment = Array(1).fill('NOT_CHECKED')
                    }
                }

                itemsTemp.push(item)
                return null;
            })

            setItems(itemsTemp)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const soma = roundUp(items.reduce((acumulador, item) => acumulador + (item.pagar || 0.0), 0), 2)

        setDivideValue(soma)
        setValueSelected(soma)
        // eslint-disable-next-line
    }, [items])

    function getPercentualPago(item) {
        if (totalItemReal(item) > 0) {
            return roundUp(((item.amountPaid || 0.0) * 100) / totalItemReal(item), 0)
        }

        return 100
    }

    function getRestoAPagar(item) {//calcula o valor excedente ou faltante para o total do item e retorna esse valor se ele for entre 10 e -10 centavos
        if (totalItemReal(item) > 0) {
            if (((item.amountPaid || 0.0) + (item.pagar || 0.0)) - totalItemReal(item) >= -0.1 && 0.1 >= ((item.amountPaid || 0.0) + (item.pagar || 0.0)) - totalItemReal(item)) {
                var resto = totalItemReal(item) - ((item.amountPaid || 0.0) + (item.pagar || 0.0))
                return resto
            }
        }

        return 0.0
    }

    const onClickAddOne = (item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)
        itemTemp.divideInto += 1

        itemTemp.pagar = undefined
        itemTemp.partialPayment = Array(itemTemp.divideInto).fill('NOT_CHECKED')

        setItems(itemsCopy)
    }

    const onClickRemoveOne = (item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        if (itemTemp.divideInto === 1) {
            return
        }

        itemTemp.divideInto -= 1
        itemTemp.pagar = undefined
        itemTemp.partialPayment = Array(itemTemp.divideInto).fill('NOT_CHECKED')

        setItems(itemsCopy)
    }

    return (
        <React.Fragment>
            <StyledTableContainer style={{ maxHeight: 500, width: '100%' }} className={classes.scrollBar}>
                <Table size='small' aria-label='resumo do pedido' stickyHeader >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none" className={classes.paddingLeft} >Item</TableCell>
                            <TableCell align='right'>Qtde</TableCell>
                            <TableCell align='right'>Total</TableCell>
                            <TableCell align='center'>Pago</TableCell>
                            <TableCell align='center'>Dividir em</TableCell>
                            <TableCell padding="checkbox">Pagar</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {items?.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableRow >

                                    <TableCell component='th' scope='row' padding="none" className={classes.paddingLeft} >
                                        <Typography>{item.name}</Typography>
                                    </TableCell>

                                    <TableCell align='right'>{quantityFormat(item.quantity)}</TableCell>

                                    <TableCell align='right'>
                                        {totalItemReal(item) === 0 ? 'Pago' : `${currencyFormat(totalItemReal(item), { simbol: '' })}`}
                                    </TableCell>

                                    <TableCell align='center'>
                                        <Tooltip title={`Total item: ${currencyFormat(totalItemReal(item), { simbol: '' })} 
                                                        | Pago ${currencyFormat(item.amountPaid || 0.0, { simbol: '' })}
                                                        | Saldo ${currencyFormat(totalItemReal(item) - (item.amountPaid || 0.0), { simbol: '' })}`}>
                                            <div>
                                                <CircularProgressWithLabel
                                                    thickness={2}
                                                    value={getPercentualPago(item)}
                                                    label={`${getPercentualPago(item)}%`} />
                                            </div>
                                        </Tooltip>
                                    </TableCell>


                                    <TableCell align='center'>
                                        {totalItemReal(item) === 0 ? 'Pago' : (
                                            <Box>
                                                <IconButton
                                                    disabled={item.received || false}
                                                    component="span"
                                                    size='medium'
                                                    onClick={() => onClickAddOne(item)}>
                                                    <AddIcon />
                                                </IconButton>

                                                {item.divideInto}

                                                <IconButton
                                                    disabled={item.received || false}
                                                    component="span"
                                                    size='medium'
                                                    onClick={() => onClickRemoveOne(item)}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </TableCell>

                                    <TableCell align='left' >
                                        {item.partialPayment?.map((itemChecked, index) => (
                                            <Checkbox
                                                key={index}
                                                disabled={itemChecked === 'RECEIVED' || totalItemReal(item) === 0}
                                                color="primary"
                                                checked={totalItemReal(item) === 0 || (itemChecked === 'CHECKED') || (itemChecked === 'RECEIVED')}
                                                onClick={(event) => handleChangeCheckBox(event, item, index)}
                                                name='checked' />
                                        ))
                                        }
                                    </TableCell>

                                    <TableCell align='right'>
                                        {item?.pagar === 0 ? 'Pago' : currencyFormat(item?.pagar, { simbol: '' })}
                                    </TableCell>

                                </TableRow>

                                {item?.subItems?.map((sub, index) => (
                                    <TableRow key={index} selected={item.notSaved}>

                                        <TableCell colSpan={1.5} component='th' scope='row'>
                                            {'+ '.concat(sub.name)}
                                        </TableCell>

                                        <TableCell align='right'>{quantityFormat(sub.quantity)}</TableCell>

                                        <TableCell align='right'>{currencyFormat(sub.totalPrice, { simbol: '' })}</TableCell>

                                        <TableCell></TableCell>

                                        <TableCell></TableCell>

                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer >

            <Box pt={2}>
                {dividedValue > 0 &&
                    <Alert variant="outlined" severity='success'>
                        {`Os itens selecionados totalizaram ${currencyFormat(dividedValue)}.`}
                    </Alert>
                }
            </Box>

        </React.Fragment >


    );
}
