import React, { useState, useEffect } from 'react';
import {
    FormControl, FormControlLabel, Grid, List, ListItemButton, ListItemIcon,
    ListItemText, ListSubheader,
    MenuItem,
    Radio, RadioGroup, TextField, Typography
} from '@mui/material';
import FormSection from "../../../layout-components/form-components/FormSection"
import { TextMaskPhone, TextMaskCPF, TextMaskCNPJ } from "../../../../util/mask"
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ptBRLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextValidator } from 'react-material-ui-form-validator';
import SecondaryAddress from "./SecondaryAddress"
import AddressForm from "./AddressForm"
import SecondaryContact from "./SecondaryContact"
import AddIcon from '@mui/icons-material/Add';
import AuthService from '../../../../service/AuthService';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { formatPhoneBR } from '../../../../util/formatter';



export default function Customer({ customer, setCustomer }) {

    const [secondaryAddressDialogOpen, setSecondaryAddressDialogOpen] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState(null)

    const [secondaryContactDialogOpen, setSecondaryContactDialogOpen] = useState(false)
    const [selectedContact, setSelectedContact] = useState(null)

    const [productsDeliveryFee, setProductsDeliveryFee] = useState([])

    const enderecos = customer.enderecos || []
    const contatos = customer.contatos || []
    const isPJ = customer.tipo === "J"
    const editCustomer = !!customer.id

    useEffect(() => {
        let isMounted = true;

        let service = new AuthService();
        service.get("/api-v2/produtoCardapio/listDeliveryFee")
            .then(productsFee => {
                if (isMounted) {
                    productsFee = orderProductsDelivery(productsFee)//sempre que ocorre alteração nos parametros reordena as taxas de entrega
                    setProductsDeliveryFee(productsFee)
                }
            }).catch(err => {
                console.error(err)
            })

        return () => {
            isMounted = false;
        };

    }, [])

    function orderProductsDelivery(produtosTaxa) { //ordena as taxas de entrega por ordem alfabética
        var taxasOrdenadas = produtosTaxa //recebe a lista de taxas de entrega

        taxasOrdenadas.sort(function (a, b) { //de acordo com o calculo abaixo ordena o item na lista
            return (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)
        })

        return taxasOrdenadas
    }

    const handleChange = (event) => {
        const attr = event.target.name
        const value = event.target.value
        setCustomer({ ...customer, [attr]: value })
    }

    const handleChangeUpper = (event) => {
        const attr = event.target.name
        const value = String(event.target.value).toUpperCase()
        setCustomer({ ...customer, [attr]: value })
    }


    const handleDateChange = (date, attr) => {
        setCustomer({ ...customer, [attr]: date })
    };

    const handleAddNewAddress = () => {
        setSelectedAddress({ id: null, pessoa: customer, municipio: customer.municipio })
        setSecondaryAddressDialogOpen(true)
    }

    const handleEditSecondaryAddress = (address) => {
        console.log("handleEditSecondaryAddress", address)
        setSelectedAddress(address)
        setSecondaryAddressDialogOpen(true)
    }

    const handleSaveAddress = (address, action) => {
        let enderecos = (customer.enderecos || []).filter(endereco => endereco.id !== address.id)

        if (action === "SAVED") {
            enderecos.push(address)
            enderecos.sort((a, b) => (a.id - b.id))
        }
        setCustomer({ ...customer, enderecos })
    }

    const handleAddNewContact = () => {
        setSelectedContact({ id: null, tipo: 'FONE', pessoa: customer })
        setSecondaryContactDialogOpen(true)
    }

    const handleEditSecondaryContact = (contact) => {
        setSelectedContact(contact)
        setSecondaryContactDialogOpen(true)
    }

    const handleSaveContact = (contact, action) => {
        let contatos = (customer.contatos || []).filter(contato => contato.id !== contact.id)

        if (action === "SAVED") {
            contatos.push(contact)
            contatos.sort((a, b) => (a.id - b.id))
        }

        setCustomer({ ...customer, contatos })
    }


    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>

                <Grid container spacing={3}>

                    <Grid item xs={12} md={8}>

                        <FormSection  >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row name="tipo" value={customer.tipo || 'F'} onChange={handleChange}>
                                            <FormControlLabel value="F" control={<Radio size="small" />} label="Física" />
                                            <FormControlLabel value="J" control={<Radio size="small" />} label="Jurídica" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator label="Nome" name="nome" value={customer.nome || ''}
                                        variant="outlined" size="small"
                                        validators={['required']}
                                        errorMessages={['obrigatório']}
                                        onChange={handleChangeUpper}
                                        fullWidth autoFocus />
                                </Grid>

                                {
                                    customer.tipo === 'J' && (
                                        <Grid item xs={12}>
                                            <TextField label="Fantasia" name="nomeFantasia" variant="outlined"
                                                size="small" fullWidth value={customer.nomeFantasia || ''}
                                                onChange={handleChangeUpper} />
                                        </Grid>
                                    )
                                }


                                <Grid item xs={12} sm={4}>
                                    <TextField label={isPJ ? "CNPJ" : "CPF"} name="cpfCnpj" variant="outlined"
                                        size="small" fullWidth value={customer.cpfCnpj || ''}
                                        InputProps={{
                                            inputComponent: (isPJ ? TextMaskCNPJ : TextMaskCPF)
                                        }}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField label={isPJ ? "I.E" : "RG"} name="rgIc" variant="outlined"
                                        size="small" fullWidth value={customer.rgIc || ''}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <DatePicker
                                        label="Nascimento"
                                        format="dd/MM/yyyy"
                                        disableFuture
                                        value={customer.dataNascimento ? new Date(customer.dataNascimento) : null}
                                        onChange={value => handleDateChange(value, 'dataNascimento')}
                                        variant="outlined"
                                        fullWidth
                                        inputVariant="outlined"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />

                                </Grid>


                            </Grid>
                        </FormSection>

                        <FormSection title="Contato principal" >

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <TextField label="Telefone" name="fone" variant="outlined" size="small"
                                        fullWidth value={customer.fone || ''}
                                        onChange={handleChange}
                                        InputProps={{
                                            inputComponent: TextMaskPhone
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField label="E-mail" name="email" type="email" size="small"
                                        variant="outlined" fullWidth value={customer.email || ''}
                                        onChange={handleChange} />
                                </Grid>

                            </Grid>
                        </FormSection>


                        <FormSection title="Endereço principal" >

                            <AddressForm address={customer}
                                setAddress={setCustomer}
                                handleChange={handleChange} />

                            <Grid container spacing={1} style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label="Taxa de entrega"
                                        value={customer.servicoProduto || ''}
                                        onChange={handleChange}
                                        name="servicoProduto"
                                        helperText="Selecione a taxa mais adequada"
                                    >
                                        <MenuItem value={"-1"}>
                                            --
                                        </MenuItem>
                                        {productsDeliveryFee.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>

                        </FormSection>

                        <FormSection >
                            <TextField label="Observações do cliente"
                                name="observacoes" variant="outlined" size="small"
                                fullWidth value={customer.observacoes || ''}
                                onChange={handleChange} multiline />
                        </FormSection>

                    </Grid>

                    <Grid item xs={12} md={4} >

                        {!editCustomer && (
                            <FormSection >
                                <Typography variant="caption">Para adicionar endereços secundários é necessário salvar</Typography>
                            </FormSection>
                        )}

                        {editCustomer && (
                            <div>

                                <SecondaryAddress
                                    open={secondaryAddressDialogOpen}
                                    setOpen={setSecondaryAddressDialogOpen}
                                    selectedAddress={selectedAddress}
                                    returnAddress={handleSaveAddress}
                                    productsDeliveryFee={productsDeliveryFee} />


                                <FormSection  >
                                    <List dense >
                                        <ListSubheader disableGutters>
                                            Endereços secundários
                                        </ListSubheader>

                                        {
                                            editCustomer && enderecos.map(endereco => (
                                                <ListItemButton
                                                    key={endereco.id}
                                                    onClick={() => handleEditSecondaryAddress(endereco)} >
                                                    <ListItemText
                                                        primary={`${endereco.endereco}, ${endereco.numero || ''}`}
                                                        secondary={`${endereco.bairro}`}
                                                    />
                                                </ListItemButton>
                                            ))
                                        }

                                        <ListItemButton onClick={handleAddNewAddress}>
                                            <ListItemIcon>
                                                <AddIcon size="small" color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary="Adicionar"
                                                primaryTypographyProps={{
                                                    color: 'secondary'
                                                }} />
                                        </ListItemButton>
                                    </List>

                                </FormSection>


                                <SecondaryContact
                                    open={secondaryContactDialogOpen}
                                    setOpen={setSecondaryContactDialogOpen}
                                    selectedContact={selectedContact}
                                    returnContact={handleSaveContact}
                                />


                                <List dense subheader={
                                    <ListSubheader disableGutters>
                                        Contatos secundários
                                    </ListSubheader>
                                }>

                                    {
                                        contatos.map(contato => (
                                            <ListItemButton key={contato.id} onClick={() => handleEditSecondaryContact(contato)}>
                                                <ListItemIcon>
                                                    {contato.tipo === 'EMAIL' && <MailIcon fontSize="small" />}
                                                    {contato.tipo === 'FONE' && <PhoneIcon fontSize="small" />}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={contato.tipo === 'FONE' ? formatPhoneBR(contato.valor) : contato.valor}
                                                />
                                            </ListItemButton>
                                        ))
                                    }

                                    <ListItemButton onClick={handleAddNewContact}>
                                        <ListItemIcon>
                                            <AddIcon size="small" color='secondary' />
                                        </ListItemIcon>
                                        <ListItemText primary="Adicionar"
                                            primaryTypographyProps={{
                                                color: 'secondary'
                                            }} />
                                    </ListItemButton>
                                </List>
                            </div>
                        )}


                    </Grid>
                </Grid>

            </LocalizationProvider>

        </div>
    );
}