import AuthService from './AuthService'

// está classe estende a classe AuthService mas diferentemente desta,
// não armazena os dados no localStorage para não sobrescrever o da aplicação logada
class AuthServiceTransient extends AuthService {

    constructor(domain) {
        super(domain)

        this.token = null
        this.profile = null
    }

    setProfile(profile) {
        this.profile = profile
    }

    getProfile() {
        return this.profile
    }

    setToken(token) {
        this.token = token
    }

    getToken() {
        return this.token
    }

    logout() {
        this.setToken(null)
        this.setProfile(null)
    }

    // carrega os dados solicitados através da url informada da API
    get(url, obj) {
        return fetch(this.domain + url, {
            method: 'GET',
            body: obj ? JSON.stringify(obj) : null,
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(this._checkStatus).then(response => response.json())
    }

    post(url, obj) {
        return fetch(this.domain + url, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then(this._checkStatus).then(response => response.json())
    }

}

export default new AuthServiceTransient()