import React, { useMemo, useState } from 'react'
import AuthService from '../../../service/AuthService'
import { debounce } from 'lodash'
import { Autocomplete, MenuItem, TextField } from '@mui/material'

/** 
* Componente de autocomplete para procurar produto
*
* Você pode alterar a url de listagem utilizando o parâmetro `listUrl`
* caso precise de um exemplo no JAVA de como criar uma função diferente de listagem de uma olhada no método
* `listWithoutStep` do `ProdutoAPI.java`
*
* Caso queria deixar desabilitado produtos já selecionados use o parâmetro `alreadySelected`
*
* @example
* <ProductSearch
*   value={value}
*   label="Produto"
*   listUrl="listWithoutStep"
*   // lista de produtos já selecionados
*   alreadySelected={[{...}]}
*   onConfirm={(produto) => handleConfirm(produto)} />
*/
export default function ProductSearch({ label, value, alreadySelected = [], listUrl = 'list', onConfirm }) {
    const service = new AuthService()
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    function isAlreadySelected(produto) {
        return alreadySelected.some(item => item.idProduto === produto.idProduto)
    }

    function handleSearch(value) {
        if (!value) {
            setOptions([])
            return
        }

        setLoading(true)

        service.get(`/api-v2/produto/${listUrl}?q=${value}&ativo=true&max=10`)
            .then(produtos => setOptions(produtos))
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleSearchDebounce = useMemo(() => debounce(handleSearch, 200), [])

    return (
        <Autocomplete
            fullWidth
            autoHighlight
            value={value}
            loading={loading}
            variant="outlined"
            options={options || []}
            onChange={(event, item) => onConfirm(item)}
            getOptionLabel={(option) => option ? option.descricao : ''}
            renderInput={(params) => <TextField {...params} label={label} />}
            onInputChange={(event, newInputValue) => handleSearchDebounce(newInputValue)}
            isOptionEqualToValue={(option, value) => (option.idProduto === value.idProduto)}
            renderOption={(props, option) => {
                const disabled = isAlreadySelected(option)

                return (
                    <MenuItem
                        {...props}
                        key={option.idProduto}
                        disabled={disabled}>
                        {option.descricao}
                    </MenuItem>
                )
            }} />
    )
}