import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AuthService from '../../../../service/AuthService';
import { useSnackbar } from 'notistack';
import { HeaderDetailOrder } from '../../DetailOrder';
import LogisticList from '../../../../component/dialogLogistic/logisticList';
import { useLoadEntregadores } from '../../../deliveryman/components/LoadDeliveryMan';

export default function SelectDeliveryman({ open, handleClose, pedidoIntegracao, onConfirm }) {

    const { enqueueSnackbar } = useSnackbar();
    const [options] = useLoadEntregadores()
    const [entregadorSelecionado, setEntregadorSelecionado] = useState(null);
    const [order, setOrder] = useState(null)

    let inputRef;

    useEffect(() => {
        const pessoaEntregador = pedidoIntegracao?.pessoaEntregador;
        setEntregadorSelecionado(pessoaEntregador)
        setOrder(pedidoIntegracao)
    }, [pedidoIntegracao]);


    //A partir daqui são as funções de logistica

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (inputRef) {
                    inputRef.focus()
                }
            }, 100);
        }
        // eslint-disable-next-line
    }, [open]);

    const handleConfirm = () => {
        let pedidoIntegracaoToSave = Object.assign({}, pedidoIntegracao)
        pedidoIntegracaoToSave.pessoaEntregador = entregadorSelecionado;

        const idEntregador = entregadorSelecionado?.id || -1

        let service = new AuthService()

        service.post(`/api-v2/pedidoIntegracao/entregador/${pedidoIntegracaoToSave.id}/${idEntregador}`)
            .then(pedidoIntegracaoSaved => {
                enqueueSnackbar('Entregador alterado com sucesso.', { variant: "success" });
                handleClose()
                if (onConfirm) {
                    onConfirm(pedidoIntegracaoSaved)
                }
            }).catch(err => {
                console.error(err)
            })
    }

    const handleCancel = () => {
        handleClose()
    }

    return (
        <div>
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleCancel}
                open={open} >
                <DialogTitle>

                    <Box py={2}>
                        <Typography
                            variant="h4" component="h4"
                            display="block" >Informe o entregador
                        </Typography>
                    </Box>

                    {pedidoIntegracao && <HeaderDetailOrder order={pedidoIntegracao} />}

                </DialogTitle>
                <DialogContent>
                    <Box pt={1}>
                        <Autocomplete
                            value={entregadorSelecionado || null}
                            options={options}
                            getOptionLabel={(option) => option.nome}
                            fullWidth
                            onChange={(event, newValue) => {
                                setEntregadorSelecionado(newValue);
                            }}
                            selectOnFocus
                            renderInput={
                                (params) => <TextField
                                    inputRef={input => { inputRef = input; }}
                                    {...params}
                                    label="Entregador"
                                    variant="outlined" />
                            }
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.nome}</li>}
                            isOptionEqualToValue={(option, value) => (option.id === (value && value.id))}
                        />
                    </Box>

                    <LogisticList order={order} hideIfNothingEnable={true} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleConfirm} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>


        </div>
    );
}