import { createStore } from 'redux';
import { createToogleThemeMode, initialStateTheme } from "./themeStore"
import {
    initialStateBoard,
    moveCard,
    loadBoardFromData,
    filterBoard,
    loadBoardFromOrders,
    updateBoardFromEvents,
    addOrUpdateBoardFromOrder,
    removeOrderFromOrder
} from "./boardStore"

import { initialStateDialogDetail, toogleDetailOrderDialog } from "./dialogDetailStore"
import { initialStateDialogImport, toogleImportDialog } from "./dialogImportStore"
import { initialStateDialogOrder, toogleDialogOrder, updateOrderEdit } from "./dialogOrder"
import { tooglePaymentDialog, initialPaymentDialog } from "./dialogPaymentOrder"
import { sendMessageSocket, initialStateSocket, updateSocket } from "./sokectStore"
import { setGeneralParameters, initialGeneralParameters } from './parameterStore'
import { setGenericCodes, initialGenericCodes } from './genericCodesStore'
import { setPrinterLocations, initialPrinterLocations } from './printerLocationStore'
import { toogleDiscountDialog, initialDiscountDialog } from './dialogDiscountStore'
import { toogleDrawerIntegracoes, initialDrawerIntegracoes } from './drawerIntegracoesStore'
import { toogleDialogDeliveryman, initialStateDialogDeliveryman } from './dialogDeliveryman'
import { toogleInvoiceDialog, initialInvoiceDialog } from './dialogInvoice'
import { toogleDialogLogistics, initialDialogLogistics } from './dialogLogistics';
import { addOrUpdateOrderOnCard, initialCards, removeOrderOnCard, setCards, setCardsOposto } from './identificadorStore';
import { addOrUpdateGenericFromOrder, removeOrderGeneric } from './genericBoardIdentificadorStore';
import { initialDialogDialogListAllOrder, toogleDialogDialogListAllOrder } from './dialogListAllOrder';
import { initialDialogOrderListByIdentificador, toogleDialogOrderListByIdentificador } from './dialogOrderListByIdentificador';
import { initialDialogJoinOrders, toogleDialogJoinOrders } from './dialogJoinOrders';
import { updateGlocalCache, initialGlobalCache } from './globalCache';
import { initialAdditionaInformation, toogleAdditionalInformation } from './dialogAdditionalInformation';
import { initialMoneyBoxStatus, updateMoneyBoxStatus } from './updateMoneyBoxStatus';
import { initialStateDialogEditItem, toogleDialogEditItem } from './dialogEditItem';
import { closeOrderDialog, initialStateCloseOrderDialog } from './dialogCloseOrder';
import { initialStateListItemGrouped, listItemGroupedDialog } from "./dialogListItemsGrouped";
import { createBoardKds, updateItemKds, createItemKds, updateItemsKds } from '../pages/kds/store';
import { initialStateMinimizedOrders, updateMinimizedOptions, updateMinimizedOptionsFromLocalstorage } from './dialogMinimizedOrders';
import { initialStateOrderBy, toggleOrderByMode } from './orderByStore'
import { initialDiscountItemDialog, toogleDiscountItemDialog } from './dialogDiscountItemStore';
import { initialStateConnection, updateConnection } from './connectionStore';
import { initialStateDialogDetailOffline, toogleDetailOrderDialogOffline } from './dialogDetailOfflineStore';
import { initialDialogSendOrders, toogleDialogSendOrders } from './dialogSendOrders';
import { initialTefDialog, toogleTefDialog } from './dialogTefStore';
import { initialTefSaleInfoDialog, toogleTefSaleInfoDialog } from './dialogTefSaleInfo';
import { initialSimpleBackdrop, toogleSimpleBackdrop } from './simpleBackdropStore';
import { initialStatePaymentScreens, updatePaymentScreens } from './paymentScreensStore';
import { initialMinuteUpdateActualDate, updateMinuteUpdateActualDate } from './minuteUpdateStore';
import { initialWhatsApp, updateWhatsAppStatus } from './whatsappStore';
import { initialDialogSelectWaiter, toogleDialogSelectWaiter } from './dialogSelectWaiter';

const INITIAL_STATE
    = {
    theme: initialStateTheme(),
    boardState: initialStateBoard(),
    detailOrder: initialStateDialogDetail(),
    importOrder: initialStateDialogImport(),
    dialogOrder: initialStateDialogOrder(),
    paymentOrder: initialPaymentDialog(),
    socketContext: initialStateSocket(),
    generalParameters: initialGeneralParameters(),
    genericCodes: initialGenericCodes(),
    discountOrder: initialDiscountDialog(),
    drawerIntegracoes: initialDrawerIntegracoes(),
    detailDialogDeliveryman: initialStateDialogDeliveryman(),
    invoiceOrder: initialInvoiceDialog(),
    dialogLogistics: initialDialogLogistics(),
    initialCards: initialCards(),
    dialogOrderListByIdentificador: initialDialogOrderListByIdentificador(),
    dialogJoinOrders: initialDialogJoinOrders(),
    globalCache: initialGlobalCache(),
    additionalInformation: initialAdditionaInformation(),
    dialogListAllOrder: initialDialogDialogListAllOrder(),
    statusMoneyBox: initialMoneyBoxStatus(),
    detailDialogEditItem: initialStateDialogEditItem(),
    closeOrder: initialStateCloseOrderDialog(),
    listItemsGrouped: initialStateListItemGrouped(),
    printerLocations: initialPrinterLocations(),
    minimizedOptions: initialStateMinimizedOrders(),
    orderBy: initialStateOrderBy(),
    discountItemDialog: initialDiscountItemDialog(),
    connection: initialStateConnection(),
    detailOrderOffline: initialStateDialogDetailOffline(),
    dialogSendOrders: initialDialogSendOrders(),
    dialogTef: initialTefDialog(),
    dialogTefSaleInfo: initialTefSaleInfoDialog(),
    simpleBackdrop: initialSimpleBackdrop(),
    paymentScreensStatus: initialStatePaymentScreens(),
    minuteUpdateActualDate: initialMinuteUpdateActualDate(),

    whatsapp: initialWhatsApp(),

    dialogSelectWaiter: initialDialogSelectWaiter(),

}

function reciveAction(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'TOOGLE_THEME_MODE':
            return createToogleThemeMode(state);

        case 'MOVE_CARD':
            return moveCard(state, action);

        case 'LOAD_BOARD_FROM_DATA':
            return loadBoardFromData(state, action);

        case 'LOAD_BOARD_FROM_ORDERS':
            return loadBoardFromOrders(state, action);

        case 'FILTER_BOARD':
            return filterBoard(state, action);

        case 'UPDATE_BOARD_FROM_EVENTS':
            return updateBoardFromEvents(state, action);

        case 'ADD_OR_UPDATE_BOARD_FROM_ORDER':
            return addOrUpdateBoardFromOrder(state, action);

        case 'REMOVE_ORDER_ON_BOARD':
            return removeOrderFromOrder(state, action);

        case 'TOOGLE_DETAIL_ORDER_DIALOG':
            return toogleDetailOrderDialog(state, action);

        case 'OPEN_DIALOG_CLOSE_ORDER':
            return closeOrderDialog(state, action);

        case 'TOOGLE_IMPORT_ORDER_DIALOG':
            return toogleImportDialog(state, action);

        case 'TOOGLE_DIALOG_ORDER':
            return toogleDialogOrder(state, action);

        case 'UPDATE_ORDER_EDIT':
            return updateOrderEdit(state, action);

        case 'TOOGLE_PAYMENT_DIALOG':
            return tooglePaymentDialog(state, action);

        case 'TOOGLE_DISCOUNT_DIALOG':
            return toogleDiscountDialog(state, action);

        case 'SEND_MESSAGE_SOCKET':
            return sendMessageSocket(state, action);

        case 'UPDATE_SOCKET':
            return updateSocket(state, action);

        case 'SEND_GENERAL_PARAMETERS':
            return setGeneralParameters(state, action);

        case 'TOOGLE_DRAWER_INTEGRACOES':
            return toogleDrawerIntegracoes(state, action);

        case 'TOOGLE_DIALOG_DELIVERYMAN':
            return toogleDialogDeliveryman(state, action);

        case 'TOOGLE_DIALOG_EDIT_ITEM':
            return toogleDialogEditItem(state, action);

        case 'TOOGLE_DIALOG_INVOICE':
            return toogleInvoiceDialog(state, action);

        case 'TOOGLE_DIALOG_LOGISTICS':
            return toogleDialogLogistics(state, action);

        case 'LOAD_CARDS_FROM_DATA':
            return setCards(state, action);

        case 'ADD_UPDATE_ORDER_ON_CARD':
            return addOrUpdateOrderOnCard(state, action);

        case 'REMOVE_ORDER_ON_CARD':
            return removeOrderOnCard(state, action);

        case 'ADD_OR_UPDATE_GENERIC_FROM_ORDER':
            return addOrUpdateGenericFromOrder(state, action);

        case 'REMOVE_ORDER_GENERIC':
            return removeOrderGeneric(state, action);

        case 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR':
            return toogleDialogOrderListByIdentificador(state, action);

        case 'TOOGLE_DIALOG_JOIN_ORDERS':
            return toogleDialogJoinOrders(state, action);

        case 'UPDATE_GLOBAL_CACHE':
            return updateGlocalCache(state, action);

        case 'ADD_UPDATE_ADDITIONAL_INFORMATION_ON_ORDER':
            return toogleAdditionalInformation(state, action);

        case 'TOOGLE_DIALOG_ALL_ORDER':
            return toogleDialogDialogListAllOrder(state, action);

        case 'UPDATE_MONEY_BOX_STATUS': // utilizado para atualizar o estado do caixa 
            return updateMoneyBoxStatus(state, action);

        case 'LIST_ITEM_GROUPED_DIALOG':
            return listItemGroupedDialog(state, action);

        case 'CREATE_BOARD_KDS':
            return createBoardKds(state, action);

        case 'UPDATE_ITEM_KDS':
            return updateItemKds(state, action);

        case 'UPDATE_ITEMS_KDS':
            return updateItemsKds(state, action);

        case 'CREATE_ITEM_KDS':
            return createItemKds(state, action);

        case 'SEND_GENERAL_CODE':
            return setGenericCodes(state, action);

        case 'SEND_PRINTER_LOCATIONS':
            return setPrinterLocations(state, action);

        case 'UPDATE_MINIMIZED_OPTIONS':
            return updateMinimizedOptions(state, action);

        case 'RELOAD_MINIMIZED_OPTIONS_FROM_LOCAL_STORAGE':
            return updateMinimizedOptionsFromLocalstorage(state, action);

        case 'TOGGLE_ORDER_BY_MODE':
            return toggleOrderByMode(state, action);

        case 'LOAD_OPPOSITE_CARDS_FROM_DATA':
            return setCardsOposto(state, action);

        case 'TOOGLE_DISCOUNT_ITEM_DIALOG':
            return toogleDiscountItemDialog(state, action);

        case 'UPDATE_CONNECTION':
            return updateConnection(state, action);

        case 'TOOGLE_DETAIL_ORDER_DIALOG_OFFLINE':
            return toogleDetailOrderDialogOffline(state, action);

        case 'TOOGLE_SEND_ORDERS_DIALOG':
            return toogleDialogSendOrders(state, action);

        case 'TOOGLE_TEF_DIALOG':
            return toogleTefDialog(state, action);

        case 'TOOGLE_TEF_SALE_INFO_DIALOG':
            return toogleTefSaleInfoDialog(state, action);

        case 'TOOGLE_SIMPLE_DROPBOX':
            return toogleSimpleBackdrop(state, action);

        case 'UPDATE_PAYMENT_SCREENS':
            return updatePaymentScreens(state, action)

        case 'UPDATE_MINUTE_UPDATE_ACTUAL_DATE':
            return updateMinuteUpdateActualDate(state, action);

        case 'WHATSAPP_STATUS_CHANGE':
            return updateWhatsAppStatus(state, action);

        case 'TOOGLE_SELECT_WAITER':
            return toogleDialogSelectWaiter(state, action)

        default:
            return state;
    }
}

const store = createStore(reciveAction);

export default store;