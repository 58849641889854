import React, { useEffect, useRef, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {
    Button, TableContainer,
    Stepper, StepLabel, Step, DialogActions, Box, Tooltip, FormControlLabel, Checkbox, Paper, Hidden, MenuItem, Menu, ListItemIcon, ListItemText, IconButton, Badge, TextField, useMediaQuery, DialogContentText, Typography, LinearProgress
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AuthService from '../../../service/AuthService';
import { useSnackbar } from 'notistack';
import { ItemObj, OrderObj } from './helper/OrderHelper'
import ResumeOrder from './pages/ResumeOrder';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux'
import GroupProduct from './pages/GroupProduct';
import Product from './pages/Product';
import TotalOrder from './pages/TotalOrder';
import MoreInfoOrder from './pages/MoreInfoOrder';
import StepModal from './pages/StepProduct/StepModal';
import { totalItems, totalOrder, createUUID, isOrderPayed, totalDiscountItems, itemnsNotSavedCount, addAllTaxaServico, printOrderHTML, generateOrderSummary } from '../../../util/order/orderUtil';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TypeOrderComponent from './TypeOrder/TypeOrderComponent';
import debounce from 'lodash/debounce';
import { useHotkeys } from 'react-hotkeys-hook';
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import { getSettings } from '../../settings/settings';
import { changeSituacaoPedidoOnServer, createCardFromPedido, getTipoImportacaoBySigla, sendPedidoToPrint, showMsgErrorOuSuccessOnReturPrint } from '../../../util/board/BoardUtil';
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import InputSearch from '../InputSearch';
import { getParamByKey } from './../../../../src/component/parameter/index'
import { ifAnyGranted, ifAnyGrantedUnidade } from '../../../util/auth/authUtil';
import { addProductByCodBar, calculatePriceAndQuantity } from "./InputCodBar"
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import Discount from './../Payment/Discount/index';
import hotkeys from 'hotkeys-js'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ButtonGroup from '@mui/material/ButtonGroup';
import { parseCodigoBarras } from '../../../util/codigoBarras/codigoBarrasHelper';
import { getGenericCodeByKey } from '../../../component/genericCode';
import MinimizeIcon from '@mui/icons-material/Minimize';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { normalizeText, dateFormat } from '../../../util/formatter';
import ReactGA from "react-ga4";
import DiscountItem from '../Payment/DiscountItem';
import SearchIcon from '@mui/icons-material/Search';
import { GroupAccordions } from './pages/GroupAccordions';
import SearchProductDialog from './pages/SearchProductDialog';
import { ResumeOrderDialog } from './ResumeOrder/ResumeOrderDialog';
import { usePdvContext } from '../../../component/generics/PdvProvider';
import { SelectWaiterBoard } from '../../../component/usuarios/Waiter/SelectWaiterBoard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from '../../../util/order/orderUtil';
import { ItemAutoObservation } from '../ItemAutoObservation';
import SelectDeliveryman from './SelectDeliveryman';

const PREFIX = 'Order';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
    scrollBar: `${PREFIX}-scrollBar`,
    backgroundColumn1: `${PREFIX}-backgroundColumn1`,
    backgroundColumn2: `${PREFIX}-backgroundColumn2`,
    iconButton: `${PREFIX}-iconButton`,
    boxGroup: `${PREFIX}-boxGroup`,
    boxProduct: `${PREFIX}-boxProduct`,
    resumeOrder: `${PREFIX}-resumeOrder`,
    dialogPadding: `${PREFIX}-dialogPadding`,
    minimizedButton: `${PREFIX}-minimizedButton`,
    headerLancamento: `${PREFIX}-headerLancamento`,
    stepsWrapper: `${PREFIX}-stepsWrapper`,
    grid: `${PREFIX}-grid`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        minHeight: '70vh',
    },

    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.backgroundColumn1}`]: {
        background: theme.palette.personal.boardCard
    },

    [`& .${classes.backgroundColumn2}`]: {
        background: theme.palette.personal.boardList
    },

    [`& .${classes.iconButton}`]: {
        padding: 10,
    },

    [`& .${classes.boxGroup}`]: {
        minHeight: 120,
        //maxHeight: 120,
        paddingRight: 4,
        [theme.breakpoints.down('lg')]: {
            // height: '12vh',
            height: '100%',
        }
    },

    [`& .${classes.boxProduct}`]: {
        height: '100%',
        paddingRight: 4,
        [theme.breakpoints.down('md')]: {
            height: '55vh',
        },
    },

    [`& .${classes.resumeOrder}`]: {
        maxHeight: '60vh',
    },

    [`& .${classes.dialogPadding}`]: {
        padding: "5px 5px"
    },

    [`& .${classes.minimizedButton}`]: {
        position: 'absolute',
        right: theme.spacing(6),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        padding: 8
    },

    [`& .${classes.headerLancamento}`]: {
        zIndex: 1,
    },

    [`& .${classes.stepsWrapper}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',

        '& .MuiStep-root': {
            padding: '0 3px',
        },

        '& .MuiStepLabel-labelContainer': {
            display: 'none',
        },

        '& .MuiSvgIcon-root.MuiStepIcon-root': {
            color: theme.palette.primary.main,
        },

        '& .MuiStepIcon-root.Mui-active': {
            outline: `1px solid ${theme.palette.common.white}`,
            borderRadius: '30px',
        },

        '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed': {
            color: theme.palette.primary.dark,
        },

        '& .MuiStepper-root': {
            backgroundColor: theme.palette.primary.main,
            padding: '8px 10px 10px',
            borderRadius: '0 0 20px 20px',
        }
    },

    [`& .${classes.grid}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr 4fr',
        height: 'calc(100% - 80px)',

        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '2fr 4fr',
        }
    }

}))

const isEmpty = require('lodash/isEmpty');

function getSteps() {
    return ['Tipo do pedido', 'Escolha os itens', 'Info complementares' /*, 'Forma de pagamento' */];
}

export function useOrder() {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { confirmDialog, promptDialog } = usePdvContext()
    const generalParameters = useSelector(state => state.generalParameters)
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com'


    function cancelarPedidoKDS(pedido, justificativa = null) {
        const LOCAL_NOTIFICACAO_ITEM = getParamByKey(generalParameters, 'LOCAL_NOTIFICACAO_ITEM')?.valorString

        if (['KDS', 'AMBOS'].includes(LOCAL_NOTIFICACAO_ITEM)) {
            const serviceImpressao = new AuthService(URL_SERVIDOR_IMPRESSAO)
            const profile = serviceImpressao.getProfile()

            const order = {
                id: pedido.id,
                motivoCancelamento: justificativa ?? pedido.motivoCancelamento
            }

            const options = {
                method: 'POST',
                headers: {
                    "unidadeuuid": profile.unidade.uuid,
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(order)
            }

            serviceImpressao.request('/api/kds/cancelarItemsByPedido', options).then(response => {
                response.json().then(retorno => {
                    console.log("Itens cancelados no kds", retorno)
                })
            }).catch(err => {
                console.error("Erro ao tentar cancelar os itens no kds", err)
            })
        }

    }

    async function cancelOrder(order, motivo, openDetail) {
        try {

            let saveLogTef = false
            const situacao = 'CANCELADO'

            if (order?.iFoodOrder?.payments.some(payment => payment?.formaPagamento?.utilizaTef)) {
                await confirmDialog({
                    content: 'Existem pagamentos de TEF neste pedido, deseja mesmo continuar?',
                    confirmLabel: 'Continuar'
                })

                saveLogTef = true
            }

            let cancellationCode
            let motivoCancelamento = motivo || await promptDialog({
                content: 'Informe o motivo do cancelamento',
                order: order,
                setCancellationCode: (value) => {
                    cancellationCode = value
                }
            })

            if (motivoCancelamento === null) {
                return
            }

            changeSituacaoPedidoOnServer(order, situacao, motivoCancelamento, cancellationCode)
                .then(pedidoIntegracao => {

                    cancelarPedidoKDS(pedidoIntegracao)

                    dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })
                    enqueueSnackbar(`Pedido #${pedidoIntegracao.id} alterado para ${pedidoIntegracao.situacao}`, { variant: "success" })

                    if (openDetail) {
                        const updatedCard = createCardFromPedido(pedidoIntegracao)
                        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
                    }

                    if (saveLogTef) {
                        // salva log do cancelamento do pedido caso ele tenha pagamentos de TEF
                        const service = new AuthService()
                        service.post('/api-v2/logEvento', {
                            tipo: 'UPDATE',
                            entidade: 'Pedido',
                            motivo: 'Cancelado pedido que continua pagamento de TEF. Motivo: ' + motivoCancelamento,
                            json: JSON.stringify(pedidoIntegracao),
                        })
                    }
                }).catch(err => {
                    console.error(err)
                    extractErrorMessage(err, "Falha ao alterar status do situação")
                        .then(msg => {
                            enqueueSnackbar(msg, { variant: "error" })
                        })
                })
        } catch (err) {
            console.log(err)
        }
    }

    return { cancelOrder, cancelarPedidoKDS }
}

function Order() {
    const dispatch = useDispatch()

    const minimizedOrders = useSelector(state => state.minimizedOptions.minimizedOrders)
    const open = useSelector(state => state.dialogOrder.open)
    const order = useSelector(state => state.dialogOrder.order)
    const productMenu = useSelector(state => state.globalCache.productMenu)
    const groups = useSelector(state => state.globalCache.groups)
    const listDeliveryFee = useSelector(state => state.globalCache.listDeliveryFee)
    const initActiveStep = useSelector(state => state.dialogOrder.initActiveStep)
    const generalParameters = useSelector(state => state.generalParameters)
    const isMobileProductFilter = getSettings().enableMobilePDV ? getSettings().enableMobilePDV === "true" : false;
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

    const steps = getSteps()
    const [productFiltered, setProductFiltered] = React.useState(null)
    const [activeGroup, setActiveGroup] = React.useState({})
    const [activeStep, setActiveStep] = React.useState(initActiveStep || 0)
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [openStep, setOpenStep] = React.useState(false)
    const [openDiscount, setOpenDiscount] = React.useState(false)
    const [lastProductMenu, setLastProductMenu] = React.useState({});
    const [itemOrder, setItemOrder] = React.useState(null)
    const service = new AuthService()
    const productsRef = useRef([])
    const btnConfirmRef = useRef()
    const btnConfirmAndPayRef = useRef()
    const btnDiscountRef = useRef()
    const itemRef = useRef(null)
    const [saving, setSaving] = useState(false)
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [loadingGroups, setLoadingGroups] = useState(false)
    const cardList = useSelector(state => state.cards)
    const [anchorElMaisOpcoes, setAnchorElMaisOpcoes] = React.useState(null);
    const [showProductsMobile, setShowProductsMobile] = useState(false)
    const [countNotSavedItens, setCountNotSavedItens] = useState(0)
    const [quantidadeProduto, setQuantidadeProduto] = useState(null);
    const [openModalQtd, setOpenModalQtd] = useState(null)
    const tableContainerItensRef = useRef()
    const FORMATO_DEFAULT = "V" //V ou P para codigos de balança
    const FORMATO = getParamByKey(generalParameters, 'MODELO_COD_BARRAS_BALANCA')?.valorString || FORMATO_DEFAULT;
    const IDENTIFICADOR_SECUNDARIO_OBRIGATORIO = getParamByKey(generalParameters, 'IDENTIFICADOR_SECUNDARIO_OBRIGATORIO')?.valor
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO');
    const PERMITE_LANCAR_ITENS_IDENTIFICADOR_SEM_PESSOA = getParamByKey(generalParameters, 'PERMITE_LANCAR_ITENS_IDENTIFICADOR_SEM_PESSOA')?.valor
    const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com';
    const [closeDialog, setCloseDialog] = React.useState(false)
    const orderRef = useRef(null)
    const [openSearchProduct, setOpenSearchProduct] = useState(false)
    const notifyProductAddedPosition = useRef('top')
    const TAXA_SERVICO_PEDIDO_BALCAO = getParamByKey(generalParameters, 'TAXA_SERVICO_PEDIDO_BALCAO')?.valorString === 'S';
    const { cancelOrder } = useOrder()
    const [openWaiter, setOpenWaiter] = useState(false)
    const [selectedWaiter, setSelectedWaiter] = React.useState(null)
    const [openAutoObservation, setOpenAutoObservation] = useState(false)
    const [openDialogEntregador, setOpenDialogEntregador] = React.useState(false)
    const [orderCopyLogistica, setOrderCopyLogistica] = React.useState(false)
    const [isProdutobalanca, setIsProdutoBalanca] = React.useState({
        isBalanca: false,
        codigoBarras: ''
    })
    const [searchValue, setSearchValue] = useState('')
    const [itemStepForObservation, setItemStepForObservation] = useState(null)

    const setOrder = (order) => {
        dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { order: order, open } })
    }

    const setProductMenu = (productMenu) => {
        dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { productMenu: productMenu } })
    }

    const setGroups = (groups) => {
        dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { groups: groups } })
    }

    const setListDeliveryFee = (listDeliveryFee) => {
        dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { listDeliveryFee: listDeliveryFee } })
    }

    hotkeys('f10', 'order', (event) => {
        event.preventDefault()
        event.stopPropagation()

        let enable = (open && !openStep) || openDiscount

        if (!enable) {
            return;
        }

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    }, [openStep, open])

    useHotkeys('Ctrl+d', (event) => {
        event.preventDefault()

        if (btnDiscountRef.current) {
            btnDiscountRef.current.click()
        }

    });

    useHotkeys('f9', (event) => {
        event.preventDefault()

        if (btnConfirmAndPayRef.current) {
            btnConfirmAndPayRef.current.click()
        }

    });

    const onSelectWaiter = (waiter) => {
        handleNext(2)
    }

    const onConstructor = (saving = false) => {
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: false, order: new OrderObj() } })

        setProductFiltered(null)
        setActiveGroup({})
        setActiveStep(0)
        setSelectedCustomer(null)
        setLastProductMenu({})
        setItemOrder(null)
        setSaving(saving)
    }

    const handleFocusOnFirtsProduct = () => {
        if (productsRef.current) {
            if (productsRef.current[0]) {
                productsRef.current[0].focus()
            }
        }
    };

    const handleMinimizeOrder = (order) => {

        let minimizedOrdersCopy = Object.assign([], minimizedOrders)
        minimizedOrdersCopy.push(order)
        dispatch({ type: 'UPDATE_MINIMIZED_OPTIONS', minimizedOptions: { minimizedOrders: minimizedOrdersCopy } })

        onConstructor()
    }

    function getProductList() {
        return productFiltered || productMenu || []
    }

    const handleClickGroup = useCallback(group => {
        if (!group) {
            setActiveGroup({})
            setProductFiltered(null)
            return
        }

        setActiveGroup(group)
        const productMenuCopy = Object.assign([], productMenu)
        const productsFilter = productMenuCopy.filter(itemCardapio => itemCardapio.produto.idGrupoProduto === group.id)
        setProductFiltered(productsFilter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, productMenu])

    function handleCopyItem(item) {
        item = JSON.parse(JSON.stringify(item));

        let itemCopia = new ItemObj()
        itemCopia.referenceCode = createUUID()
        itemCopia.externalCode = item.externalCode;
        itemCopia.quantity = item.quantity;
        itemCopia.price = item.price;
        itemCopia.name = item.name;
        itemCopia.totalPrice = item.totalPrice;
        itemCopia.observations = item.observations;
        itemCopia.tipoImportacao = item.tipoImportacao
        itemCopia.localNotification = item.localNotification

        if (item.subItems) {
            itemCopia.subItems = Object.assign([], item.subItems);
        }

        setItemOrder(itemCopia)
        enqueueSnackbar(`Item '${itemCopia.name}' adicionado ao pedido`);
    }

    const handleQuantityModal = (options) => {

        if (openModalQtd != null) {
            setOpenModalQtd(null)
        } else {
            setOpenModalQtd(options);
        }

    }

    const handleSelectProductBalanca = (item, options) => {
        handleClickAddProduct(item, { ...options, snackbarPlacement: 'bottom' })
    }

    const saveQtd = () => {
        // eslint-disable-next-line eqeqeq
        if (quantidadeProduto < 0 || quantidadeProduto == "" || quantidadeProduto == " ") {
            enqueueSnackbar("Valor inválido, por favor informe um número positivo", { variant: "error" })
            return
        }
        const { productMenu, options } = openModalQtd;
        const produto = productMenu.produto

        if (isProdutobalanca.isBalanca) {
            addProductByCodBar(isProdutobalanca.codigoBarras, handleSelectProductBalanca, products, FORMATO)
        } else {
            addProduct(productMenu, options, produto);
        }
        setOpenModalQtd(null);
    }

    const addProduct = (productMenu, options, produto) => {
        console.log(productMenu, options, produto)
        const hasStep = !!(produto.abrirPassos === 'S' && productMenu.complementos)

        notifyProductAddedPosition.current = options?.snackbarPlacement || 'top'

        let newQuantity = 1
        if (quantidadeProduto) {
            newQuantity = quantidadeProduto
        } else if (options?.quantity) {
            newQuantity = options.quantity
        }

        let { quantity, price } = {
            /*VERIFICA SE O VALOR DA QUANTIDADE É MAIRO QUE ZERO, CASO CONTRÁRIO SETA COM O VALOR PADRÃO: 1*/
            ...options,
            quantity: newQuantity,
            price: produto.valorUnitarioVenda,
        }

        productMenu.quantity = quantity

        setQuantidadeProduto(null)

        if (!hasStep) {

            /*caso o produto tenha local de notificação pega do produto, caso não tenha pega do grupo*/
            const notification = produto.tipoNotificacao ? produto.tipoNotificacao : produto.grupoProduto?.tipoNotificacao

            let item = new ItemObj()
            item.referenceCode = createUUID()
            item.externalCode = produto.idProduto
            item.product = produto
            item.quantity = quantity
            item.price = price
            item.name = produto.descricao
            let totalPrice = quantity * price;
            item.totalPrice = parseFloat(totalPrice.toFixed(2))
            item.localNotification = notification || "NN"
            item.tipoImportacao = order?.tipoImportacao ? order.tipoImportacao : getTipoImportacaoBySigla("SO").sigla
            item.productChargeServiceFee = (produto.cobrarTaxaServico ?? true)
            item.chargeServiceFee = item.productChargeServiceFee


            const pessoaDoProfile = AuthService.profile().pessoa;

            if (pessoaDoProfile && !ifAnyGranted('ROLE_GARCOM_PEDIDO')) {
                item.pessoaVendedor = {
                    id: pessoaDoProfile.id,
                    nome: pessoaDoProfile.nome
                }
            }

            if (notification === 'NN') {
                item.status = 'NAO_NOTIFICAR'
                item.statusKds = 'NAO_NOTIFICAR'
            }

            if(produto?.observacaoAutomatica){
                setOpenAutoObservation(produto?.observacaoAutomatica)
                if(itemStepForObservation){
                    setItemStepForObservation(null)
                }
            }

            setItemOrder(item)
            notifyProductAdded()
        }
        setLastProductMenu(productMenu)
        setOpenStep(hasStep)        
    }

    const notifyProductAdded = () => {
        if (isMobile) {// exibe a snack bar de adicionar produto ao carrinho quando está em smartphone
            enqueueSnackbar('Produto adicionado com sucesso', {
                variant: 'info',
                action: () => (
                    <Box >
                        <Button color="grey" size="small" onClick={() => setShowProductsMobile(!showProductsMobile)}> Mostrar </Button>
                    </Box>
                ),
                autoHideDuration: 1500,
                disableWindowBlurListener: true,
                transitionDuration: { appear: 50, enter: 50, exit: 50 },
                anchorOrigin: {
                    vertical: notifyProductAddedPosition.current,
                    horizontal: 'left'
                }
            })
        }
    }

    function handleClickOpenSteps(item) {

        if (item.subItems && item.subItems.length > 0) {
            enqueueSnackbar(`O item '${item.name}' já tem adicionais. É necessário excluír o item e adicioná-lo novamente.`, { variant: "error" });
            return;
        }

        const productId = item.externalCode
        const productMenuFounded = productMenu.find(element => String(element.produto.idProduto) === String(productId))

        if (!productMenuFounded) {
            enqueueSnackbar(`Item '${item.name}' não foi encontrado na listagem de produtos`, { variant: "error" });
            return;
        }

        if (!productMenuFounded.complementos || productMenuFounded.complementos.length === 0) {
            enqueueSnackbar(`Item '${item.name}' não possui passos configurados`, { variant: "warning" });
            return;
        }

        itemRef.current = item;
        setLastProductMenu(productMenuFounded)
        setOpenStep(true)
    }

    useEffect(() => {
        if (itemOrder) {
            let orderTemp = Object.assign({}, order)

            if (!orderTemp.iFoodOrder.items)
                orderTemp.iFoodOrder.items = []

            orderTemp.iFoodOrder.items.push(itemOrder)

            setOrder(orderTemp)

            updateScrollItens()

        }
        // eslint-disable-next-line
    }, [itemOrder])


    const updateScrollItens = () => {
        setTimeout(() => {
            const itemContainer = tableContainerItensRef.current;
            if (itemContainer) {
                //let offsetTop = itemContainer.offsetTop
                let offsetHeight = itemContainer.scrollHeight
                itemContainer.scroll(0, offsetHeight)
            }

        }, 300)
    }

    const handleNext = (paramStep, openPaymentOrder) => {
        let step = activeStep

        if (paramStep) {
            step = paramStep
        }

        if (step === 2) {
            if (isValidOrder()) {
                setSaving(true)
                let orderCopy = Object.assign({}, order)
                console.log('Copia do order: ', orderCopy)
                // eslint-disable-next-line no-unused-expressions, array-callback-return
                orderCopy?.iFoodOrder?.items?.map(item => {
                    if (!!!item.pessoaVendedor && selectedWaiter) {
                        item.pessoaVendedor = selectedWaiter.pessoa
                    }

                    // eslint-disable-next-line no-unused-expressions, array-callback-return
                    item?.subItems?.map(subItem => {
                        if (!!!subItem.pessoaVendedor && selectedWaiter) {
                            subItem.pessoaVendedor = selectedWaiter.pessoa
                        }

                        // eslint-disable-next-line no-unused-expressions, array-callback-return
                        subItem?.subItems?.map(itemSubItem => {
                            if (!!!itemSubItem.pessoaVendedor && selectedWaiter) {
                                itemSubItem.pessoaVendedor = selectedWaiter.pessoa
                            }
                        })
                    })
                })

                if (orderCopy.tipo === 'COMANDA' || orderCopy.tipo === 'MESA') {
                    if (ifAnyGranted('ROLE_GARCOM_PEDIDO') && orderCopy?.iFoodOrder?.items?.some(item => !!!item.pessoaVendedor)) {
                        setOpenWaiter(true)
                        setSaving(false)
                        return
                    }
                }
                setSelectedWaiter(null)

                if ((orderCopy.tipo === 'COMANDA' || orderCopy.tipo === 'MESA') || (orderCopy.tipo === 'BALCAO' && TAXA_SERVICO_PEDIDO_BALCAO)) {
                    const UTILIZA_TAXA_SERVICO = getParamByKey(generalParameters, 'UTILIZA_TAXA_SERVICO')?.valorString

                    if (orderCopy.tipo !== 'BALCAO')
                        orderCopy.iFoodOrder.deliveryMethod.mode = 'COMANDA'

                    if (UTILIZA_TAXA_SERVICO === 'S') {
                        const PERCENTUAL_TAXA_SERVICO = getParamByKey(generalParameters, 'PERCENTUAL_TAXA_SERVICO')?.valor

                        if (PERCENTUAL_TAXA_SERVICO > 0) {
                            const COBRAR_TAXA_VALOR_BRUTO_PEDIDO = getParamByKey(generalParameters, 'COBRAR_TAXA_VALOR_BRUTO_PEDIDO')
                            addAllTaxaServico(orderCopy.iFoodOrder, PERCENTUAL_TAXA_SERVICO, COBRAR_TAXA_VALOR_BRUTO_PEDIDO)
                        }
                    }
                }

                orderCopy.iFoodOrder.subTotal = totalItems(orderCopy.iFoodOrder)
                orderCopy.iFoodOrder.totalPrice = totalOrder(orderCopy.iFoodOrder)

                setOrder(orderCopy)

                const printOrder = orderCopy.printOrder;
                console.log("ANTES DE MANDAR LOG EXCLUSAO", orderCopy.deletedItems)

                if (orderCopy.deletedItems && orderCopy.deletedItems.length > 0) {

                    service.post('/api-v2/log/saveListItens', orderCopy.deletedItems)
                        .then(deletedItem => {
                            console.log("Itens excluídos foram salvos nos logs", deletedItem)
                        }).catch(err => {
                            console.error("Erro ao tentar salvar itens excluídos", err)
                        });

                    const LOCAL_NOTIFICACAO_ITEM = getParamByKey(generalParameters, 'LOCAL_NOTIFICACAO_ITEM')?.valorString

                    if (['KDS', 'AMBOS'].includes(LOCAL_NOTIFICACAO_ITEM)) {
                        const serviceImpressao = new AuthService(URL_SERVIDOR_IMPRESSAO)
                        const profile = serviceImpressao.getProfile()

                        orderCopy.deletedItems.map(item => {
                            let itemKds = {
                                pedidoKds: {
                                    id: orderCopy.id
                                },
                                item: {
                                    codigoReferencial: item.referenceCode,
                                    justificativaCancelamento: item.dadosExclusao.justificativaExclusao
                                }
                            }

                            const options = {
                                method: 'POST',
                                headers: {
                                    "unidadeuuid": profile.unidade.uuid,
                                    "Accept": "application/json",
                                    "Content-Type": "application/json; charset=utf-8",
                                },
                                body: JSON.stringify(itemKds),
                            }

                            serviceImpressao.request('/api/kds/cancelarItem', options).then(response => {
                                response.json().then(
                                    retorno => {
                                        console.log("Itens cancelados no kds", retorno)
                                    }
                                )
                            }).catch(err => {
                                console.error("Erro ao tentar cancelar os itens no kds", err)
                            });
                            return null;
                        })
                    }
                }

                const NOTIFICAR_PAGAMENTO_PEDIDO = getParamByKey(generalParameters, 'NOTIFICAR_PAGAMENTO_PEDIDO')

                if (orderCopy.tipo === 'BALCAO' && orderCopy.iFoodOrder?.items?.length > 0 && !!NOTIFICAR_PAGAMENTO_PEDIDO.valor) {
                    orderCopy.iFoodOrder.items.map((item) => {
                        if (item.status === "PENDENTE") {
                            item.status = "NOTIFICAR_PAGAMENTO"
                            orderCopy.printOrder = false;
                        }
                        return null;
                    });

                    if ("NAO_NOTIFICADO" === order.statusNotificacao) {
                        orderCopy.statusNotificacao = "NOTIFICAR_PAGAMENTO"
                    }

                    const profile = new AuthService().getProfile();
                    const data = new Date();
                    orderCopy.logs = !!orderCopy.logs ? orderCopy.logs + " \n" : ""
                    orderCopy.logs = orderCopy.logs + "Pedido: " + orderCopy.id + " Marcado como NOTIFICAR_PAGAMENTO todos os itens. Para impressão após pagamento pelo usuário: " + profile.nome + ` em ${dateFormat(data)}  Parâmetro NOTIFICAR_PAGAMENTO_PEDIDO ativo`
                }

                if (!orderCopy.iFoodOrder.levarTrocoPara) {
                    orderCopy.iFoodOrder.levarTrocoPara = null
                }

                console.log('orderCopy', orderCopy)

                service.post('/api-v2/pedidoIntegracao', orderCopy)
                    .then(savedOrder => {
                        console.log('savedOrder', savedOrder)

                        if ((savedOrder.tipo === 'BALCAO' || openPaymentOrder) && savedOrder.tipo !== 'CAIXA_RAPIDO') {
                            if (openPaymentOrder) {
                                //Envia para o analytics a informação do clique no botão de confirmar e receber pedido
                                ReactGA.event({
                                    category: 'Button',
                                    action: 'Click confirmar e receber',
                                    label: 'Botão de confirmar e receber pedido'
                                });
                            }

                            const canImportOrder = isOrderPayed(savedOrder?.iFoodOrder)

                            //abre a tela de pagamento caso for tipo Balcão
                            if (!canImportOrder) {
                                const updatedCard = createCardFromPedido(savedOrder)
                                savedOrder.printOrder = openPaymentOrder;
                                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })
                                dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: savedOrder } })
                            }

                        }

                        // ATUALIZA CARD EM TODOS
                        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder })
                        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder } })

                        // caso seja venda rapida será aberta a tela de pagamento e salvo o pedido no local storage
                        if (savedOrder.tipo === 'CAIXA_RAPIDO') {
                            //abre de edição de proudutos
                            setOrder(savedOrder)
                            dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: savedOrder } })

                            localStorage.setItem('_fast_sale_', JSON.stringify(savedOrder))
                        }

                        onConstructor(true)

                        try {

                            /* FAZ IMPRESSAO DO PEDIDO, SE FOR O CASO */
                            if (!!printOrder && !openPaymentOrder && (!!!NOTIFICAR_PAGAMENTO_PEDIDO.valor || savedOrder.tipo !== 'BALCAO')) {
                                let printApplication = getSettings().printApplication

                                if (printApplication === "APP") {
                                    savedOrder.origemImpressao = "AUT FINALIZAR PEDIDO";
                                    sendPedidoToPrint(savedOrder)
                                        .then(resp => {
                                            showMsgErrorOuSuccessOnReturPrint(resp, enqueueSnackbar)
                                        }).catch(err => {
                                            console.error('Erro ao enviar pedido para impressao. Retorno: ', err)
                                            enqueueSnackbar('Erro ao enviar pedido para impressao.', { variant: "error" });
                                        })
                                } else {
                                    printOrderHTML(savedOrder.id)
                                }
                            }

                        } catch (err) {
                            enqueueSnackbar('Erro ao tentar imprimir pedido', { variant: "warning" });
                            console.error('ERRO_SALVAR_PEDIDO', err)
                        }

                        setOrderCopyLogistica(savedOrder)

                    }).catch(err => {

                        extractErrorMessage(err, "Erro ao salvar o pedido")
                            .then(msg => {
                                enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                            })
                        console.error('ERRO_IMPRIMIR_PEDIDO: ', err)
                    }).finally(() => {
                        console.log("FINALIZOU O SAVE ORDER")
                        setSaving(false)

                        if (orderCopy.tipo === 'DELIVERY') {
                            service.get('/api-v2/integracaoLogistica/list')
                                .then(listLogistica => {
                                    if (listLogistica?.length === 1 && listLogistica[0]?.descricao === 'FOODY DELIVERY') {
                                        service.put('/api-v2/pedidoIntegracao/enviarFoodyDelivery', orderCopyLogistica)
                                            .then(console.log("Enviado com sucesso"))
                                            .catch(err => {
                                                console.error(err);
                                            })
                                    } else if (listLogistica?.length >= 1) {
                                        setOpenDialogEntregador(true)
                                    }
                                }).catch(err => {
                                    extractErrorMessage(err, "Erro ao enviar para a logistica")
                                        .then(msg => {
                                            enqueueSnackbar('Não foi possível enviar para a logistica - Detalhes: ' + msg, { variant: "error" });
                                        })
                                    console.error('ERRO_IMPRIMIR_PEDIDO: ', err)
                                }).finally(() => {
                                    console.log("FINALIZOU O SAVE ORDER")
                                    setSaving(false)

                                })
                        }

                    })
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleClickCancel = (event, reason) => {
        setCloseDialog(true)
    }

    const closeDialogOptions = {
        handleCloseDialog: () => setCloseDialog(false),
        onContinue: () => {
            handleMinimizeOrder(order)
            setCloseDialog(false)
        },
        onDiscart: async () => {

            // caso seja descartada uma venda rápida o pedido é cancelado
            const fastSale = localStorage.getItem('_fast_sale_')
            if (order.tipo === 'CAIXA_RAPIDO' && fastSale) {
                const profile = new AuthService().getProfile()
                await cancelOrder(order, 'Venda rápida descartada pelo usuário ' + profile.nome)
                localStorage.removeItem('_fast_sale_')
            }

            onConstructor()
            setCloseDialog(false)
        }
    }

    const onConfirmSelectDialog = (pedidoImportacao) => {
        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao } })

        let updatedCard = createCardFromPedido(pedidoImportacao)
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
    }

    const handleChangeFilter = (value, productMenu) => {
        return new Promise((resolve, reject) => {
            const productsCopy = Object.assign([], productMenu)
            const productsFilter = productsCopy.filter(itemCardapio => normalizeText(itemCardapio.produto.descricao).indexOf(normalizeText(value)) !== -1)

            setSearchValue(value)

            resolve(productsFilter)
        }).then(products => {

            if (products.length > 0) {
                setProductFiltered(products)
            } else {
                searchByCodBar(value, productMenu)
            }

        })
    }

    const searchByCodBar = (codigo, productMenu) => {
        // eslint-disable-next-line
        const { codigoBarrasRealProduto } = parseCodigoBarras(codigo, { formato: FORMATO })
        let arrayProducts = []
        if (productMenu) {
            productMenu.map(productFather => {
                const codigoBarrasProduto = productFather.produto.codigoBarras

                if (codigoBarrasProduto === codigoBarrasRealProduto) {
                    arrayProducts.push(productFather)
                }
                return null;
            })
        }

        setProductFiltered(arrayProducts)
    }

    /**
     * Função utilizada na busca de produto, que ao clicar em 'Enter' é buscado nos produtos por nome, caso seja um único produto o adiciona 
     * ao order, caso seja mais de um não faz nada. se não encontrar nenhum produto faz a busca por cód. de barras e adiciona o produto ao 
     * order se encontrado.
     * @param {*} value 
     * @param {*} productMenu 
     * @returns 
     */
    const handleSearchProductForEnter = (value, productMenu) => {
        return new Promise((resolve, reject) => {
            const productsCopy = Object.assign([], productMenu)
            const productsFilter = productsCopy.filter(itemCardapio => normalizeText(itemCardapio.produto.descricao).indexOf(normalizeText(value)) !== -1)
            resolve(productsFilter)
        }).then(products => {

            if (products.length > 0) {
                if (products.length === 1) {
                    handleClickAddProduct(products[0])
                }
            } else {
                addProductByCodBar(value, handleClickAddProduct, productMenu, FORMATO)
            }

        })
    }

    const handleChangeFilterDelay = React.useMemo(
        () => debounce((value, productMenu) => {
            handleChangeFilter(value, productMenu)
        }, 200),
        // eslint-disable-next-line
        [],
    );

    function handleSelectCustomer(selectedCustomer) {
        setSelectedCustomer(selectedCustomer)
    }

    function handleChangePrintOrder(event) {
        let checked = event.target.checked

        let orderTemp = Object.assign({}, order)
        orderTemp.printOrder = checked

        setOrder(orderTemp)
    }

    useEffect(() => {
        //se a pessoa já está no pedido não alterar mais pq senão vai causar um loop
        if (selectedCustomer?.id === order?.iFoodOrder?.customer?.id) {
            return
        }

        if (selectedCustomer) {
            //criar o customer para atribuir no pedido
            let customerTemp = {}
            customerTemp.id = selectedCustomer.id
            customerTemp.name = selectedCustomer.nome
            customerTemp.phone = selectedCustomer.fone
            customerTemp.email = selectedCustomer.email
            customerTemp.taxPayerIdentificationNumber = selectedCustomer.cpfCnpj
            customerTemp.type = selectedCustomer.tipo

            let orderTemp = Object.assign({}, order)

            if (orderTemp && orderTemp.iFoodOrder) {
                orderTemp.iFoodOrder.customer = customerTemp
            }

            setOrder(orderTemp)
        } else {
            //SENAO LIMPA CLIENTE
            let orderTemp = Object.assign({}, order)
            orderTemp.iFoodOrder.customer = null
            setOrder(orderTemp)
        }
        // eslint-disable-next-line
    }, [selectedCustomer])


    useEffect(() => {

        if (open === true) {
            setActiveStep(initActiveStep || 0)
            hotkeys.setScope('order');
        } else {
            hotkeys.deleteScope('order')
        }
        // eslint-disable-next-line    
    }, [open])


    useEffect(() => {
        const customer = order?.iFoodOrder?.customer

        if (customer && (selectedCustomer?.id !== customer?.id)) {

            service.get(`/api-v2/pessoa/${customer.id}`)
                .then(people => {
                    setSelectedCustomer(people)
                }).catch(err => {

                    extractErrorMessage(err, "Erro ao buscar a pessoa")
                        .then(msg => {
                            enqueueSnackbar(`Erro ao buscar a pessoa - ${msg}`, { variant: "error" });
                        })
                    console.error('ERRO_BUSCA_PESSOA: ', err)
                })
        }

        if (order) {
            setCountNotSavedItens(itemnsNotSavedCount(order.iFoodOrder))
        }


        hotkeys.setScope('order');
        // eslint-disable-next-line    
    }, [order])


    useEffect(() => {

        if (groups === null) {
            setLoadingGroups(true)
            service.get('/service?action=list&entity=grupoProduto')
                .then(groupsApi => {
                    let groupsAtivo

                    if (isMobileProductFilter) {//Caso seja verdadeiro está em um smarthphone então filtra os grupos para mostrar no smarthphone 
                        groupsAtivo = groupsApi.filter(element => element.ativo && element.mostrarVersaoMobile)
                    } else {//Caso seja falso é pois está em um smarthphone portanto exibe todos os grupos de itens 
                        groupsAtivo = groupsApi.filter(element => element.ativo && element.mostrarPdvOnline)
                    }

                    setGroups(groupsAtivo)
                    setLoadingGroups(false)
                }).catch(err => {
                    extractErrorMessage(err, "Erro ao buscar os grupos de produtos")
                        .then(msg => {
                            enqueueSnackbar(`Erro ao buscar os grupos de produtos - ${msg}`, { variant: "error" });
                        })
                    console.log('ERRO_GRUPO: ' + err)
                    setLoadingGroups(false)
                })
        }

        /* SE productMenu for nulo, ainda não está em cache */
        if (productMenu === null) {
            setLoadingProducts(true)

            const filtroProduto = isMobileProductFilter ? "MOBILE" : "PDV_ONLINE"
            service.get(`/service?action=list&entity=produtoCardapio&filtroProduto=${filtroProduto}`)
                .then(productMenuAPI => {
                    let productFilterActive = productMenuAPI.filter((e) => e.produto.grupoProduto.ativo && e.produto.grupoProduto.mostrarPdvOnline)

                    productFilterActive.sort((a, b) => a.descricao <= b.descricao)

                    setProductMenu(productFilterActive)

                    setLoadingProducts(false)
                }).catch(err => {
                    enqueueSnackbar("Erro ao buscar os produtos", { variant: 'error', });
                    console.log('ERRO_PRODUTO: ' + err)
                    setLoadingProducts(false)
                })
        }

        if (listDeliveryFee === null) {
            service.get('/api-v2/produtoCardapio/listDeliveryFee')
                .then(ldf => {
                    setListDeliveryFee(ldf)
                }).catch(err => {
                    extractErrorMessage(err, "Erro ao buscar as taxas de entrega")
                        .then(msg => {
                            enqueueSnackbar(`Erro ao buscar as taxas de entrega - ${msg}`, { variant: "error" });
                        })
                })
        }
        // eslint-disable-next-line
    }, [])

    function isValidOrder() {

        if (!order.tipo) {
            enqueueSnackbar("Informe o tipo do pedido", { variant: 'error' });
            return false
        }


        if (order.tipo === 'COMANDA') {

            if (isEmpty(order.identificadorPrincipal)) {
                enqueueSnackbar(`Informe uma comanda para salvar o pedido - No passo 1`, { variant: 'error' })
                return false
            }

            if (order.identificadorSecundario) {
                const foundCard = cardList.find((card) => (card.identificador.tipo === 'MESA' && card.identificador.identificador === order.identificadorSecundario))

                if (!foundCard) {
                    enqueueSnackbar(`Mesa '${order.identificadorSecundario}' não encontrada.`, { variant: 'error' })
                    return false
                }

            }

            if (Boolean(IDENTIFICADOR_SECUNDARIO_OBRIGATORIO) === true) {
                if (!order.identificadorSecundario) {
                    enqueueSnackbar(`o campo Mesa deve ser informado.`, { variant: 'error' })
                    return false
                }
            }
        }

        if (order.tipo === 'MESA') {
            const foundCard = cardList.find((card) => (card.identificador.tipo === 'MESA' && card.identificador.identificador === order.identificadorPrincipal && card.identificador.ativo && card.identificador.status !== "BLOQUEADO"))

            if (!foundCard) {
                enqueueSnackbar(`Mesa '${order.identificadorPrincipal}' não encontrada.`, { variant: 'error' })
                return false
            }


        }

        if (order.iFoodOrder.items?.length <= 0) {
            enqueueSnackbar("Adicione pelo menos 1 item no pedido.", { variant: 'error', });
            return false
        }

        if (order.tipo === 'DELIVERY') {
            if (order.iFoodOrder.customer === undefined || order.iFoodOrder.customer === null) {
                enqueueSnackbar("Informe um cliente.", { variant: 'error', });
                return false
            }

            if (order.iFoodOrder?.deliveryMethod?.mode === 'DELIVERY') {

                if (!order.iFoodOrder.deliveryAddress) {
                    enqueueSnackbar("Selecione o endereço de entrega do cliente.", { variant: 'error', });
                    return false
                }
            }
        }

        if ((order.tipo === 'COMANDA' || order.tipo === 'MESA') && !PERMITE_LANCAR_ITENS_IDENTIFICADOR_SEM_PESSOA && !order.iFoodOrder.customer) {
            enqueueSnackbar('Informe um cliente.', { variant: 'error', })
            return false
        }

        if (order.pedidoAgendado === 'AGENDADO') {
            let now = new Date();
            let dataAgendamento = new Date(order.dataPedido);

            if (dataAgendamento.getTime() < now.getTime() && !!!order.id) {
                console.log("Order: ", order.id)
                enqueueSnackbar("A data do agendamento não pode ser retroativa.", { variant: 'error', });
                return false
            }
        }

        return true
    }

    // alterar a situação de todos os itens para pendente
    const alterarSituacaoDosItens = () => {
        let orderCopy = Object.assign({}, order)

        orderCopy.iFoodOrder.items.filter(item => item.status === "NOTIFICADO").map(item => {
            item.status = "PENDENTE"
            return null;
        })

        if ('dataConfirmacao' in orderCopy) {
            const data = new Date();
            orderCopy.logs = orderCopy.logs ? orderCopy.logs += `Pedido completo renotificado manualmente (${dateFormat(data)});` : `Pedido completo renotificado manualmente (${dateFormat(data)})`
        }

        setOrder(orderCopy)
    };

    function openModalDiscount() {
        dispatch({ type: 'TOOGLE_DISCOUNT_DIALOG', discountOrder: { open: true, order } })
        setOpenDiscount(true)
    };

    const onCompleteStepModal = (produtctIncludeOnOrder) => {
        setLastProductMenu({})
        if (produtctIncludeOnOrder) {
            notifyProductAdded()
        }
    }

    const handleKeyDownQtde = (event) => {
        if (event.keyCode === 13) { //Keycode 13 = Enter ou Return
            event.preventDefault();
            saveQtd()
        }
    }

    const closeModalQuantidade = () => {
        setOpenModalQtd(null)
        setQuantidadeProduto(null)

    }

    const toggleSearchProduct = () => {
        setOpenSearchProduct(value => !value)
    }

    const handleFinalizarObservacao = (value) => {
        const orderCopy = Object.assign({}, order)

        let itemTemp = orderCopy.iFoodOrder.items?.find(element => element.referenceCode === value.referenceCode)

        for (const i in value) {
            itemTemp[i] = value[i];
        }

        dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { order: orderCopy, open } })
    }


    const ultimoPasso = activeStep === steps.length - 1;
    const products = getProductList()

    const handleClickAddProduct = useCallback((productMenu, options, value) => {
        const produto = productMenu.produto
        let codigo = !!value ? value : searchValue
        let openKgPanel = (options && options.openKgPanel !== undefined) ? options.openKgPanel : true;
        const { pareceCodBarBalanca, qtde, valorUnitario, valorTotal, qtdeUnitario } = parseCodigoBarras(codigo, { formato: FORMATO })

        if (produto.unidadeMedida.sigla === 'KG' && pareceCodBarBalanca) {
            const newOptions = options ? options : calculatePriceAndQuantity({
                qtde,
                produto,
                valorTotal,
                qtdeUnitario,
                valorUnitario,
                pareceCodBarBalanca,
                formato: FORMATO,
            })

            addProduct(productMenu, newOptions, produto)
        } else if (produto.unidadeMedida.sigla === 'KG' && openKgPanel) {
            handleQuantityModal({ productMenu, options });
        } else {
            addProduct(productMenu, options, produto);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, order])

    return (
        <React.Fragment>
            <StyledDialog
                maxWidth={"sm"}
                open={openModalQtd != null}
                onClose={closeModalQuantidade}
                scroll="paper">
                <DialogTitle onClose={closeModalQuantidade}>
                    Quantidade do produto (KG)
                </DialogTitle>

                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="SpaceBetween" pt={1}>

                        <TextField
                            type="number"
                            variant='outlined'
                            label='Quantidade'
                            autoFocus
                            defaultValue={1.00}
                            onFocus={event => event.target.select()}
                            onChange={(e) => setQuantidadeProduto(e.target.value)}
                            onKeyDown={handleKeyDownQtde}
                            name='quantity'
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                }
                            }}
                        />

                        <Button
                            style={{ marginLeft: "10px" }}
                            variant='contained'
                            color='primary'
                            onClick={saveQtd}>
                            Salvar
                        </Button>

                    </Box>
                </DialogContent>
            </StyledDialog>
            <StyledDialog
                maxWidth={false}
                fullWidth
                fullScreen={isMobile}
                open={open}
                onClose={handleClickCancel}
                scroll="paper"
                ref={orderRef}
            >
                <DialogTitle onClose={handleClickCancel} className={classes.headerLancamento}>
                    {isMobile ? 'Lançamento' : 'Lançamento de pedido'}

                    {["DELIVERY", "BALCAO"].includes(order?.tipo) && (
                        <Tooltip title="Continuar pedido depois">
                            <IconButton
                                className={classes.minimizedButton}
                                onClick={() => handleMinimizeOrder(order)}
                                size="large">
                                <MinimizeIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                </DialogTitle>

                <DialogContent className={clsx([classes.dialogPadding, classes.scrollBar])}>
                    <Grid container className={classes.dialogPaper} spacing={1}>
                        <Grid item xs={12} md={7} lg={8}>
                            <Box
                                display="flex"
                                flexDirection='column'
                                height='100%'
                            >
                                <Box className={classes.stepsWrapper}>
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>
                                                    <Hidden mdDown>{label}</Hidden>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>

                                <Box flexGrow={1}>

                                    {activeStep === 0 && order && (
                                        <TypeOrderComponent order={order} setOrder={setOrder}
                                            selectedCustomer={selectedCustomer}
                                            setSelectedCustomer={setSelectedCustomer}
                                            handleSelectCustomer={handleSelectCustomer}
                                            handleCopyItem={handleCopyItem} />
                                    )}

                                    {activeStep === 1 && (
                                        <>
                                            {isMobile ?
                                                <GroupAccordions groups={groups} products={products} handleProductClick={handleClickAddProduct} />
                                                :
                                                <Box className={classes.grid}>

                                                    <Box className={clsx([classes.boxGroup])}>
                                                        <Typography
                                                            variant="h6"
                                                            textAlign="center"
                                                            marginLeft="12px"
                                                            marginRight="14px">
                                                            GRUPOS
                                                        </Typography>

                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => handleClickGroup()}
                                                            fullWidth
                                                            sx={{
                                                                margin: '9px 2px 2px',
                                                                width: '98%'
                                                            }}
                                                        >
                                                            Todos
                                                        </Button>

                                                        <GroupProduct
                                                            handleClickGroup={handleClickGroup}
                                                            groups={groups}
                                                            activeGroup={activeGroup}
                                                            loading={loadingGroups} />
                                                    </Box>

                                                    <Box style={{ height: '100%' }}>
                                                        <Typography variant="h6" textAlign="center">
                                                            {activeGroup?.descricao ? activeGroup.descricao : 'TODOS OS PRODUTOS'}
                                                        </Typography>

                                                        <Box display='flex' flexDirection='row'>
                                                            <Box flexGrow={1} py={1} pr={1} p={0.2}>
                                                                <InputsearchProcuct
                                                                    handleChangeFilterDelay={handleChangeFilterDelay}
                                                                    productMenu={productMenu}
                                                                    handleFocusOnProduct={handleFocusOnFirtsProduct}
                                                                    handleSearchProductForEnter={handleSearchProductForEnter}
                                                                    productFiltered={productFiltered} />
                                                            </Box>
                                                        </Box>

                                                        <div className={clsx([classes.boxProduct])}>
                                                            <Product handleClickAddProduct={handleClickAddProduct}
                                                                products={products}
                                                                activeGroup={activeGroup}
                                                                productsRef={productsRef}
                                                                loading={loadingProducts} />
                                                        </div>

                                                    </Box>
                                                </Box>
                                            }
                                        </>
                                    )}

                                    {activeStep === 2 && (
                                        <Box
                                            display="flex"
                                            flexDirection='column'
                                            height='100%'>
                                            <MoreInfoOrder
                                                order={order}
                                                setOrder={setOrder}
                                                customer={selectedCustomer}
                                                listDeliveryFee={listDeliveryFee} />
                                        </Box>
                                    )}

                                </Box>
                            </Box>


                        </Grid>

                        <Grid item xs={12} md={5} lg={4}>
                            <Box
                                display="flex"
                                flexDirection='column'
                                height='100%'
                            >

                                <Hidden mdDown>
                                    <Box
                                        display="flex"
                                        flexGrow={1}
                                        flexDirection='column'
                                        variant="outlined"
                                        component={Paper}
                                    >

                                        <Box paddingTop={0} flexGrow={1} >
                                            <TableContainer ref={tableContainerItensRef}
                                                className={clsx(classes.resumeOrder, classes.scrollBar)}>
                                                <ResumeOrder
                                                    order={order}
                                                    setOrder={setOrder}
                                                    handleClickOpenSteps={handleClickOpenSteps} />
                                            </TableContainer>
                                        </Box>


                                    </Box>
                                </Hidden>

                                {(!isMobile || activeStep === 2) ? (
                                    <Box paddingTop={1} alignContent="flex-end">
                                        <TotalOrder order={order} showPendingAmount={false} />
                                    </Box>
                                ) : null}

                            </Box>

                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions disableSpacing >
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={1} >
                        {activeStep === 1 && isMobile &&
                            <Grid item>
                                <IconButton aria-controls="buscar-produtos"
                                    aria-haspopup="true"
                                    size="small"
                                    onClick={toggleSearchProduct}>
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        }

                        <Hidden mdUp>
                            <Grid item >
                                <IconButton onClick={() => setShowProductsMobile(!showProductsMobile)} size="large">
                                    <Badge
                                        badgeContent={countNotSavedItens} color="primary">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Grid>
                        </Hidden>

                        <Grid item >
                            <IconButton
                                aria-controls="mais-opcoes"
                                aria-haspopup="true"
                                size="small"
                                onClick={event => setAnchorElMaisOpcoes(event.currentTarget)}>
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="mais-opcoes"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                anchorEl={anchorElMaisOpcoes}
                                keepMounted
                                open={Boolean(anchorElMaisOpcoes)}
                                onClose={event => setAnchorElMaisOpcoes(null)}
                            >
                                <MenuItem >
                                    <ListItemIcon>
                                        <FormControlLabel control={
                                            <Checkbox name="printOrder" checked={(order && order.printOrder) || false} onChange={handleChangePrintOrder} />
                                        } label="Imprimir ao confirmar" />
                                    </ListItemIcon>
                                </MenuItem>

                                <Tooltip title="Clique para copiar o resumo do pedido para a área de transferência" aria-label="Copiar resumo do pedido" disableInteractive>
                                    <Box component="span">
                                        <MenuItem
                                            disabled={!order || order.iFoodOrder?.items?.length === 0}
                                            onClick={() => {
                                                const summary = generateOrderSummary(order);
                                                copyToClipboard(summary, enqueueSnackbar);  // Passe enqueueSnackbar como um argumento
                                                setAnchorElMaisOpcoes(null);
                                            }}>

                                            <ListItemIcon>
                                                <ContentCopyIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Copiar resumo do pedido" />

                                        </MenuItem>
                                    </Box>
                                </Tooltip>

                                <Tooltip title="'Ctrl+D' - Desconto" aria-label="Desconto pedido" disableInteractive>
                                    <Box component="span">
                                        <MenuItem
                                            disabled={order?.iFoodOrder?.items?.length <= 0}
                                            ref={btnDiscountRef}
                                            onClick={event => { openModalDiscount(); setAnchorElMaisOpcoes(null) }}>


                                            <ListItemIcon>
                                                <MoneyOffIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Desconto" />


                                        </MenuItem>
                                    </Box>
                                </Tooltip>

                                {ifAnyGrantedUnidade('ROLE_UTILIZA_NOTIFICACAO') &&
                                    <Tooltip title="Marca todos os itens como pendente na produção" disableInteractive>
                                        <Box component="span">
                                            <MenuItem
                                                disabled={order?.iFoodOrder?.items?.length <= 0}
                                                onClick={alterarSituacaoDosItens}>
                                                <ListItemIcon>
                                                    <NotificationsNoneIcon style={{ color: '#ffc107' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Enviar novamente os itens para produção" />


                                            </MenuItem>
                                        </Box>
                                    </Tooltip>
                                }

                            </Menu>
                        </Grid>

                        <Grid item>
                            <Tooltip title="Passo anterior" aria-label="Passo anterior" disableInteractive>
                                <Box component="span">
                                    <IconButton disabled={activeStep === 0} onClick={handleBack} size="large">
                                        <ArrowBackIosIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Grid>

                        <Grid item>

                            <Box component="span">
                                <ButtonGroup variant="contained" color="secondary"  >
                                    {!(activeStep === steps.length - 1) &&
                                        <Tooltip title={`'F10' - Próximo passo`} aria-label="Próximo passo" disableInteractive>
                                            <Box component="span">
                                                <Button
                                                    ref={!ultimoPasso && btnConfirmRef}
                                                    color="secondary"
                                                    disabled={saving}
                                                    endIcon={activeStep === steps.length - 1 ? <DoneIcon /> : <ArrowForwardIosIcon />}
                                                    size="small"
                                                    onClick={handleNext}>
                                                    {activeStep === steps.length - 1 ? saving ? 'Confirmando...' : 'Confirmar' : 'Próximo'}
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    }

                                    <Tooltip title={`${activeStep === steps.length - 1 ? 'F10 - ' : ''} Confirmar pedido`} aria-label="Próximo passo" disableInteractive>
                                        <Box component="span">
                                            <Button
                                                ref={ultimoPasso ? btnConfirmRef : null}
                                                size="small"
                                                color="primary"
                                                variant='contained'
                                                disabled={saving}
                                                onClick={() => handleNext(2)}
                                                startIcon={activeStep === steps.length - 1 ? <DoneIcon /> : null}
                                            >
                                                {activeStep === steps.length - 1 ? null : <DoneIcon />}
                                                {activeStep === steps.length - 1 ? (saving ? 'Confirmando... ' : 'Confirmar ') : ' '}
                                            </Button>
                                        </Box>
                                    </Tooltip>

                                    {(activeStep === steps.length - 1) &&
                                        <Tooltip title={`F9 - Confirmar e receber pedido`} aria-label="Confirmar e receber pedido" disableInteractive>
                                            <Box component="span">
                                                <Button
                                                    ref={btnConfirmAndPayRef}
                                                    variant="outlined"
                                                    color='primary'
                                                    size="small"
                                                    disabled={saving}
                                                    onClick={() => handleNext(2, true)}>
                                                    <AttachMoneyIcon />
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    }
                                </ButtonGroup>

                            </Box>
                            {saving ? <LinearProgress /> : null}

                        </Grid>


                    </Grid>


                </DialogActions>
            </StyledDialog>

            <SelectWaiterBoard onConfirm={onSelectWaiter}
                open={openWaiter}
                setOpen={setOpenWaiter}
                defaultWaiter={selectedWaiter}
                setSelectedWaiter={setSelectedWaiter}
                selectedWaiter={selectedWaiter} />

            <StepModal open={openStep}
                setOpenStep={setOpenStep}
                order={order}
                setOrder={setOrder}
                productMenu={lastProductMenu}
                itemRef={itemRef}
                clearProductMenu={onCompleteStepModal} 
                setOpenAutoObservation={setOpenAutoObservation}
                setItemStepForObservation={setItemStepForObservation}/>

            <Discount
                open={openDiscount}
                setOpen={setOpenDiscount}
                discountOrder={totalDiscountItems(order?.iFoodOrder)} />

            <CloseDialog
                open={closeDialog}
                handleClose={closeDialogOptions.handleCloseDialog}
                onDiscart={closeDialogOptions.onDiscart}
                onContinue={closeDialogOptions.onContinue}
                order={order} />

            <DiscountItem />

            <Hidden mdUp>
                <SearchProductDialog
                    open={openSearchProduct}
                    products={products}
                    handleClose={toggleSearchProduct}
                    handleSelectedProduct={handleClickAddProduct}
                    setProdutoBalanca={setIsProdutoBalanca}
                    produtobalanca={isProdutobalanca} />
            </Hidden>

            <Hidden mdUp>
                <ResumeOrderDialog
                    open={showProductsMobile}
                    order={order}
                    onClose={() => setShowProductsMobile(!showProductsMobile)}
                    setOrder={setOrder}
                    handleClickOpenSteps={handleClickOpenSteps} />
            </Hidden>

            <ItemAutoObservation 
                open={openAutoObservation}
                setOpen={setOpenAutoObservation}
                item={!itemStepForObservation ? itemOrder : itemStepForObservation}            
                onFinalizar={handleFinalizarObservacao}/>
            <div>
                <SelectDeliveryman
                    handleClose={() => setOpenDialogEntregador(false)}
                    open={openDialogEntregador}
                    pedidoIntegracao={orderCopyLogistica}
                    onConfirm={onConfirmSelectDialog}
                />
            </div>

        </React.Fragment>
    );
}

function InputsearchProcuct({ handleChangeFilterDelay, productMenu, handleFocusOnProduct, handleSearchProductForEnter, productFiltered }) {

    const inputSearchRef = useRef()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

    useEffect(() => {
        if (!isMobile) {
            if (inputSearchRef.current) {
                inputSearchRef.current.blur()
            }
        }
    }, [isMobile])

    useHotkeys('Ctrl+f', (event) => {
        event.preventDefault()
        if (inputSearchRef.current) {
            inputSearchRef.current.focus()
        }
    }, {
        filter: (event) => true
    })

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {//foca no primeiro item dos itens filtrados
            event.preventDefault()
            handleFocusOnProduct()
        } else if (event.key === 'Enter') {//tenta adicionar um produto pela busca no input
            if (inputSearchRef.current) {
                event.preventDefault()
                handleSearchProductForEnter(event.target.value, productMenu)
                event.currentTarget.select()
            }
        }
    }

    return (
        <InputSearch
            inputRef={inputSearchRef}
            fullWidth
            autoFocus={isMobile}
            size="small"
            onKeyDown={handleKeyDown}
            onFocus={event => event.target.select()}
            onChange={event => handleChangeFilterDelay(event.target.value, productMenu)}
            placeholder="Procurar pelo nome ou cod. barras (Ctrl+F)"
            inputProps={{ 'aria-label': 'procurar produto pelo nome' }}
        />
    )
}

function CloseDialog({ open, onDiscart, onContinue, handleClose, order }) {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                    <Typography component={'span'} variant="subtitle1" gutterBottom>
                        Deseja descartar pedido
                        {(order && order.id) ? '' : ' ou continuar depois'} ?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {(order && order.id) || (!["DELIVERY", "BALCAO"].includes(order?.tipo)) ? (
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Continuar editando
                    </Button>
                ) : (
                    <Button onClick={onContinue} color="primary" variant='outlined' autoFocus>
                        Continuar depois
                    </Button>
                )}
                <Button onClick={onDiscart} color="primary">
                    Descartar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default Order;