import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Grid,
    Box,
    IconButton,
} from '@mui/material';
import AuthService from '../../service/AuthService';
import { dateFormatedToNewFormat } from '../../util/formatter';
import { getGenericCodeByKey } from '../../component/genericCode';
import { useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PREFIX = 'PrintLogDialog'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar
}))

export default function PrintLogDialog({ open, setOpen }) {

    const [prints, setPrints] = useState(null)
    const [finding, setFinding] = useState(false)
    const [inputTextFilterValue, setInputTextFilterValue] = useState('')
    
    //const URL_SERVER_IMPRESSAO = process.env.REACT_APP_URL_SERVIDOR_IMPRESSAO
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVER_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com'
    


    useEffect(() => {
        loadPrintsFromServer()
        // eslint-disable-next-line
    }, [open])

    const tratarIDImpressao = (id = "") => {
        return id.replace(/\D/g, "");
    }

    const loadPrintsFromServer = () => {
        let profile = AuthService.profile()
        let uuid = profile.unidade.uuid;
        const url = `/api/impressao/list/${uuid}?limit=100`
        let service = new AuthService(URL_SERVER_IMPRESSAO)

        setFinding(true)

        service.get(url)
            .then(printsResults => {
                setPrints(printsResults)
            }).catch(err => {
                console.error(err)
            }).finally( ()=> {
                setFinding(false)
            })

    }

    const searchPrintsByCodRef = () => {
        const ID_PED_FORMATADO = "PEDIDO_INTEGRACAO_ID_" + inputTextFilterValue;
        let profile = AuthService.profile()
        let uuid = profile.unidade.uuid;
        const url = `/api/impressao/identificador/${ID_PED_FORMATADO}?uuid=${uuid}`
        let service = new AuthService(URL_SERVER_IMPRESSAO)

        setFinding(true)

        service.get(url)
            .then(printsResults => {
                setPrints(printsResults)
            }).catch(err => {
                console.error(err)
            }).finally( ()=> {
                setFinding(false)
            })
    }

    const onClickBuscar = () => {
        setFinding(true)
        if (inputTextFilterValue === '') {
            loadPrintsFromServer();
            console.log("Busca from server")
        } else {
            searchPrintsByCodRef();
            console.log("Busca from ref")
        }
    }

    const handleChangeInputTextFilter = (event) => {
        setInputTextFilterValue(event.target.value)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleView = (id) => {
        window.open(`/impressao-online.html?ids=${tratarIDImpressao(id)}&print=false`, '_blank', 'noreferrer')
    }

    return (
        <div>

            <StyledDialog open={open}
                onClose={handleClose}
                fullWidth maxWidth="md">

                <DialogTitle onClose={handleClose} >
                    Últimas impressões
                </DialogTitle>

                <DialogContent className={classes.scrollBar}>
                    <Grid container spacing={2}>
                        <Grid xs={9} item>
                            <Box pt={1}>
                                <TextField
                                    size="small"
                                    label={`ID do pedido`}
                                    placeholder="ID do pedido no Sischef"
                                    variant="outlined"
                                    autoFocus
                                    fullWidth
                                    onChange={handleChangeInputTextFilter}
                                />
                            </Box>
                        </Grid>
                        <Grid xs={3} item mb={1}>
                            <Box pt={1}>
                                <Button
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    disabled={finding}
                                    onClick={onClickBuscar}>
                                    {finding ? 'Buscando...' : 'Buscar'}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <TableContainer >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Local</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell align="center">Data</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Visualizar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {prints && prints.docs.map((print) => (
                                    <TableRow key={print._id}>
                                        <TableCell align="center">
                                            <div><strong>{tratarIDImpressao(print.identificador)}</strong></div>
                                            {print.origem && (<div style={{ fontSize: "8px" }}>{print.origem}</div>)}
                                        </TableCell>
                                        <TableCell align="center">{print.localImpressao}</TableCell>
                                        <TableCell component="th" scope="row">
                                                {print.descricao}
                                        </TableCell>
                                        <TableCell align="right">{dateFormatedToNewFormat(print.dataCriacao, 'ISO','dd MMM HH:mm:ss')}</TableCell>
                                        <TableCell align="right">
                                            <div style={{
                                                color: (print.statusImpressao === 'PENDENTE' ? 'red' : null)
                                            }}>{print.statusImpressao}</div>

                                        </TableCell>
                                        {print.origem !== 'TEF' &&
                                            <TableCell align="center">{
                                                <Box displayPrint="none">
                                                    <IconButton size='small' onClick={() => {handleView(print.identificador)}} >
                                                    <   VisibilityIcon />
                                                    </IconButton>
                                            </Box>
                                            }</TableCell>
                                        }
                                    </TableRow>
                                ))}
                                   
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>

            </StyledDialog>

        </div>
    );
}