import { useSelector } from 'react-redux';

export const extractErrorMessage = function(error, defaultErrorMessage) {
    return new Promise(function(resolve, reject){

       if (error.response === undefined) {
           resolve(defaultErrorMessage)
       } else {
            error.response.json().then(elem => {
                resolve(elem.errorMessage)
           });
       }
       
    })
}

/**
 * Hook para verificar conexão
 * 
 * @return {boolean} Retorna `true` se estiver conectado na internet e também no servidor
 */
export const useConnectionStatus = () => {
    const { isOnline, isConnectedToServer } = useSelector(state => state.connection)

    return isOnline && isConnectedToServer
}
