import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { Grid, Dialog, Box, TableContainer, DialogActions, Button, Typography, ButtonBase, Paper, Stepper, Step, StepLabel, useMediaQuery, Chip } from '@mui/material';
import StepResume from '../StepProduct/StepResume';
import { ItemObj, SubItemObj } from '../../helper/OrderHelper';
import { useSnackbar } from 'notistack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createUUID } from '../../../../../util/order/orderUtil';
import { currencyFormat, normalizeText } from '../../../../../util/formatter';
import { useHotkeys } from 'react-hotkeys-hook';
import { DialogTitle, DialogContent } from "../../../CustomDialog/dialogComponents"
import AutoSizer from "react-virtualized-auto-sizer";
import Tooltip from "@mui/material/Tooltip";
import { FixedSizeGrid } from 'react-window';
import debounce from 'lodash/debounce';
import InputSearch from "../../../../layout-components/InputSearch"
import AuthService from '../../../../../service/AuthService';
import { getParamByKey } from '../../../../../component/parameter';
import { useSelector } from 'react-redux';
import { parseCodigoBarras } from '../../../../../util/codigoBarras/codigoBarrasHelper';
import { roundUp } from '../../../../../util/genericUtil';
import { getTipoImportacaoBySigla } from '../../../../../util/board/BoardUtil';
import { usePdvContext } from '../../../../../component/generics/PdvProvider';
import { ifAnyGranted } from '../../../../../util/auth/authUtil';
import { ItemAutoObservation } from '../../../ItemAutoObservation';

const PREFIX = 'StepModal';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
    paper: `${PREFIX}-paper`,
    scrollBar: `${PREFIX}-scrollBar`,
    backgroundColumn1: `${PREFIX}-backgroundColumn1`,
    backgroundColumn2: `${PREFIX}-backgroundColumn2`,
    iconButton: `${PREFIX}-iconButton`,
    boxGroup: `${PREFIX}-boxGroup`,
    boxProduct: `${PREFIX}-boxProduct`,
    dialogPadding: `${PREFIX}-dialogPadding`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.dialogPaper}`]: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },

    [`& .${classes.paper}`]: {
        paddingTop: '4px',
        overflow: "hidden",
        height: "100%",
        width: '100%',
        verticalAlign: 'center'
    },

    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.backgroundColumn1}`]: {
        background: theme.palette.personal.boardCard
    },

    [`& .${classes.backgroundColumn2}`]: {
        background: theme.palette.personal.boardList
    },

    [`& .${classes.iconButton}`]: {
        padding: 10,
    },

    [`& .${classes.boxGroup}`]: {
        height: 120,
        padding: 4
    },

    [`& .${classes.boxProduct}`]: {
        maxHeight: 380,
        [theme.breakpoints.down('md')]: {
            height: '250px',
        },
    },

    [`& .${classes.dialogPadding}`]: {
        padding: "10px 10px"
    }
}));

const cellRowHeight = 50;
const cellRowWidth = 158;

function StepModal({ open, setOpenStep, setOrder, order, productMenu, clearProductMenu, itemRef, stepNivel = 2, onFinishStepNivel, setOpenAutoObservation, setItemStepForObservation }) {
    const backgroundColor = '#cfd8dc'

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const btnConfirmRef = useRef()
    const [steps, setSteps] = React.useState()
    const [activeStep, setActiveStep] = React.useState()
    const [activeStepIndex, setActiveStepIndex] = React.useState(0)
    //Lista de passos com os itens adicionados
    const [stepsItems, setStepsItems] = React.useState([])
    const GUTTER_SIZE = 4;
    const refColoumnCount = useRef(4);
    const generalParameters = useSelector(state => state.generalParameters)
    const FORMATO_DEFAULT = "V" //V ou P para codigos de balança
    const FORMATO = getParamByKey(generalParameters, 'MODELO_COD_BARRAS_BALANCA')?.valorString || FORMATO_DEFAULT;
    const productsRef = useRef([])
    const inputSearchRef = useRef()
    const { confirmDialog } = usePdvContext()
    const [openAutoObservationStep, setOpenAutoObservationStep] = React.useState(false)
    const [subItemForObservation, setSubItemForObservation] = React.useState(null)

    //const isMobile = getSettings().enableMobilePDV ? getSettings().enableMobilePDV === "true" : false;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        componentRemount()

        // eslint-disable-next-line
    }, [productMenu])


    useEffect(() => {
        if (steps) {
            setActiveStep(steps[0])
        }
    }, [steps])

    useEffect(() => {
        if (steps) {
            setActiveStep(steps[activeStepIndex])
        }
        // eslint-disable-next-line
    }, [activeStepIndex])

    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })

    const componentRemount = () => {
        if (productMenu.complementos) {
            const productMenuRealCopy = JSON.parse(JSON.stringify(productMenu))
            const stepsTemp = productMenuRealCopy.complementos.sort((a, b) => a.ordem - b.ordem)

            stepsTemp.map(step => {
                const itensSorted = step.itens?.sort((a, b) => a.produto.descricao.localeCompare(b.produto.descricao))

                if (itensSorted) {
                    step.itensOriginal = itensSorted
                    step.itens = Object.assign([], itensSorted)
                }
                return null;
            })

            setSteps(stepsTemp)
            setActiveStepIndex(0)
            setStepsItems([])
        }
    }

    const totalSteps = () => {
        if (steps) {
            return steps.length
        }
        return 0
    };

    const isFirstStep = () => {
        return activeStepIndex === 0;
    };

    const isLastStep = () => {
        return activeStepIndex === totalSteps() - 1;
    };

    const handleBack = () => {
        if (isFirstStep()) {
            return
        }

        setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {

        const stepItemTemp = stepsItems?.find(step => step.id === activeStep.id)

        if (isLastStep()) {
            if (validateStep(stepItemTemp)) {
                handleFinalizar()
            }
            return
        }


        if (validateStep(stepItemTemp)) {
            setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
        }
    };


    const handleClose = (produtctIncludeOnOrder) => {
        setOpenStep(false)
        itemRef.current = null;
        clearProductMenu(produtctIncludeOnOrder)
    };

    function addSubItem(product, productMenu, sumIfExist = false) {
        if (productMenu.maximo === 0) {
            enqueueSnackbar(`Não é permitido adicionar itens, máximo ${productMenu.maximo}`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            });

            return
        }

        let stepsItemsTemp = Object.assign([], stepsItems)
        let stepItem = null

        //tenta encontrar um passo de itens adicionados
        if (stepsItemsTemp && stepsItemsTemp.length > 0) {
            stepItem = stepsItemsTemp.find(step => step.id === productMenu.id)

            if (stepItem) {
                const canIAdd = canIAddNewItem(stepItem)
                if (!canIAdd) {
                    return false;
                }
            }

        }

        //cria o passo com a lista de subitens do passo
        if (!stepItem) {
            stepItem = {
                id: productMenu.id,
                minimo: productMenu.minimo,
                maximo: productMenu.maximo,
                ordem: productMenu.ordem,
                descricao: productMenu.descricao,
                tipoCalculo: productMenu.tipoCalculo,
                nivel: productMenu.nivel,
                items: [],
            }

            stepsItemsTemp.push(stepItem)
        }

        let subItem = stepItem.items.find((item) => item.product.idProduto === product.produto.idProduto)

        if (subItem && sumIfExist) {
            subItem.quantity++
            subItem.totalPrice = roundUp(subItem.quantity * subItem.price, 2)
        } else {
            //criar o subItem
            subItem = new SubItemObj()
            subItem.referenceCode = createUUID()
            subItem.externalCode = product.produto.idProduto
            subItem.product = product.produto
            subItem.quantity = 1
            subItem.price = product.valor
            subItem.name = product.produto.descricao
            subItem.totalPrice = product.valor
            subItem.sumInTotal = true
            subItem.productChargeServiceFee = (product.produto.cobrarTaxaServico ?? true)
            subItem.chargeServiceFee = subItem.productChargeServiceFee
            subItem.tipoImportacao = order?.tipoImportacao ? order.tipoImportacao : getTipoImportacaoBySigla("SO").sigla
            subItem.observations = product?.Observations

            subItem.productMenu = {
                produto: product.produto,
                complementos: product.complementoUltimoPasso ? [
                    product.complementoUltimoPasso
                ] : null
            }

            //adiciona o subtitem ao passo que ele pertence
            stepItem.items.push(subItem)
            if(product?.produto?.observacaoAutomatica){
                setOpenAutoObservationStep(product?.produto?.observacaoAutomatica)
                setSubItemForObservation(subItem)
            }
        }

        setStepsItems(stepsItemsTemp)

        // passar para o proximo passo
        nextStepMaxAdd(stepItem)

    }

    function nextStepMaxAdd(stepItem) {
        const min = activeStep.minimo
        const max = activeStep.maximo
        const totalQuantity = stepItem?.items.reduce((acumulador, item) => acumulador + item.quantity, 0, 0) || 0

        if (min === 0 && max === 0) {
            return false
        }

        const next = (totalQuantity >= max)
        const lastStep = isLastStep()


        if (next && !lastStep) {
            setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
        }

        return next
    }

    const canIAddNewItem = (stepItens) => {
        // console.log("canIAddNewItem", stepItens)
        const max = stepItens.maximo
        const totalQuantity = stepItens?.items ? (stepItens?.items.reduce((acumulador, item) => acumulador + item.quantity, 0, 0) || 0) : 0;

        if (totalQuantity >= max) {
            enqueueSnackbar(`Foi adicionado ${totalQuantity}. Máximo: ${max}, remova alguns itens.`, {
                variant: 'warning', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            });
            return false;
        }

        return true;
    }

    function validateStep(stepItem) {
        const min = activeStep.minimo
        const max = activeStep.maximo
        const totalQuantity = stepItem?.items?.reduce((acumulador, item) => acumulador + item.quantity, 0, 0) || 0

        //console.log(`activeStep ${activeStep.descricao} mínimo: ${min} máximo: ${max} quatidade adicionada: ${totalQuantity}`)

        if (totalQuantity >= min) {
            if (totalQuantity > max) {
                enqueueSnackbar(`Foi adicionado ${totalQuantity}. Máximo: ${max}, remova alguns itens.`, {
                    variant: 'warning', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                });
            }
        } else {
            enqueueSnackbar(`Quantidade mínima não atingida no passo ${activeStep.descricao}. Mínimo: ${min}`, {
                variant: 'warning', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            });
        }

        return (totalQuantity >= min && totalQuantity <= max)
    }

    function convertStepItemToOrderSubItem(pessoaVendedor) {
        console.log("convertStepItemToOrderSubItem 1", stepsItems)
        const stepsLevelLgOne = stepsItems.filter(element => element.nivel > 0)
        console.log("convertStepItemToOrderSubItem 2", stepsLevelLgOne)

        let subItems = []

        stepsLevelLgOne.map(step => {
            step.items.map(sub => {
                subItems.push(sub)
                if(!!!ifAnyGranted('ROLE_GARCOM_PEDIDO')){
                    sub.pessoaVendedor = pessoaVendedor
                }

                if (sub.subItems) {
                    sub.subItems.map(subNivel3 => {
                        subNivel3.level = 3
                        subNivel3.referenceCodeFather = sub.referenceCode
                        subItems.push(subNivel3)
                        if(!!!ifAnyGranted('ROLE_GARCOM_PEDIDO')){
                            subNivel3.pessoaVendedor = pessoaVendedor
                        }
                        return null;
                    })
                }
                return null;
            })
            return null;
        })
        return subItems
    }

    function convertLevelZeroForItem() {
        const stepsLevelOne = stepsItems.filter(element => element.nivel === 0)

        let items = []

        stepsLevelOne.map(step => {
            step.items.map(stepItem => {


                /*caso o produto tenha local de notificação pega do produto, caso não tenha pega do grupo*/
                const notification = stepItem.product.tipoNotificacao ? stepItem.product.tipoNotificacao : stepItem.product.grupoProduto?.tipoNotificacao

                let item = new ItemObj()
                item.referenceCode = createUUID()
                item.externalCode = stepItem.product.idProduto
                item.product = stepItem.product
                item.name = stepItem.name
                item.quantity = stepItem.quantity
                item.price = stepItem.price
                item.totalPrice = stepItem.totalPrice
                item.localNotification = notification || 'NN'
                item.ProductChargeServiceFee = (stepItem.product.cobrarTaxaServico ?? true)
                item.chargeServiceFee = item.ProductChargeServiceFee

                if (notification === 'NN') {
                    item.status = 'NAO_NOTIFICAR'
                    item.statusKds = 'NAO_NOTIFICAR'
                }

                items.push(item)
                return null;
            })
            return null;
        })

        return items
    }


    function recalculateQuantity(item) {
        stepsItems.map(step => {

            if (step.items?.length > 0) {
                const typeCalculation = step.tipoCalculo

                if (typeCalculation === 'FRACAO') {
                    let quantityAdd = 1

                    if (step?.items?.length >= 0) {
                        quantityAdd = step?.items.reduce((acumulador, item) => acumulador + item.quantity, 0)
                    }

                    let restoQtde = 1.0
                    var lastSubitem = null
                    step.items.map((sub) => {
                        const qtdeTemp = 1.0 / quantityAdd

                        sub.quantity = roundUp((qtdeTemp * sub.quantity), 2);
                        sub.totalPrice = roundUp((sub.quantity * sub.price), 2);

                        //sub.quantity = parseFloat( ( qtdeTemp * sub.quantity ).toFixed(2) )
                        //sub.totalPrice = parseFloat( ( sub.quantity * sub.price ).toFixed(2) )
                        restoQtde = restoQtde - sub.quantity

                        lastSubitem = sub
                        return null;
                    })

                    /* AJUSTA O RESTO DA QTDE PARA FECHAR SEMPRE 1.0 */
                    if (Math.abs(restoQtde) >= 0.001 && lastSubitem) {
                        lastSubitem.quantity += roundUp(restoQtde, 2); // parseFloat( ( restoQtde ).toFixed(2) )
                        lastSubitem.totalPrice = lastSubitem.quantity * lastSubitem.price
                    }

                } else if (typeCalculation === 'MAIOR_VALOR') {

                    let highestValue = step?.items?.reduce((prev, current) => {
                        return prev.price > current.price ? prev : current;
                    }).price;

                    let quantityAdd = 1
                    let restoQtde = 1.0
                    let restoValor = highestValue;
                    lastSubitem = null

                    if (step?.items?.length >= 0) {
                        quantityAdd = step?.items.reduce((acumulador, item) => acumulador + item.quantity, 0)
                    }

                    if (step?.items) {
                        step.items.map((sub) => {
                            const qtdeTemp = 1.0 / quantityAdd

                            sub.quantity = roundUp((qtdeTemp * sub.quantity), 2);
                            sub.totalPrice = roundUp((highestValue * sub.quantity), 2);
                            sub.price = highestValue;

                            restoQtde -= sub.quantity;
                            restoValor -= sub.totalPrice;

                            lastSubitem = sub
                            return null;
                        })
                    }

                    if ((Math.abs(restoQtde) >= 0.001 || Math.abs(restoValor) >= 0.001) && lastSubitem) {
                        lastSubitem.quantity = parseFloat(lastSubitem.quantity) + roundUp(restoQtde, 2);
                        lastSubitem.totalPrice = parseFloat(lastSubitem.totalPrice) + roundUp(restoValor, 2);
                    }
                }
            }
            return null;
        })
    }

    const handleClickCancel = () => {

        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja sair sem confirmar?',
        }).then(( )=> { 
            //RESETA A PAGINA
            componentRemount()

            handleClose(false)

        }).catch(() => { })
    }

    const handleFinalizar = () => {

        const produto = productMenu.produto

        // Cria o item mestre
        const newItem = !itemRef.current
        let item = itemRef.current;

        if (newItem) {
            item = new ItemObj()
            item.referenceCode = createUUID()
        }
        
        /*caso o produto tenha local de notificação pega do produto, caso não tenha pega do grupo*/
        const notification = produto.tipoNotificacao ? produto.tipoNotificacao : produto.grupoProduto?.tipoNotificacao
        item.externalCode = produto.idProduto
        item.product = produto
        item.name = produto.descricao
        item.price = produto.valorUnitarioVenda
        item.localNotification = notification || 'NN'
        item.productChargeServiceFee = (produto.cobrarTaxaServico ?? true)
        item.chargeServiceFee = item.productChargeServiceFee
        item.tipoImportacao = order?.tipoImportacao ? order.tipoImportacao : getTipoImportacaoBySigla("SO").sigla
        item.quantity = (productMenu.quantity || 1)

        let totalPrice = item.quantity * produto.valorUnitarioVenda
        item.totalPrice = parseFloat(totalPrice.toFixed(2))

        let pessoaDoProfile = AuthService.profile().pessoa;
        if (pessoaDoProfile && !ifAnyGranted('ROLE_GARCOM_PEDIDO')) {
            item.pessoaVendedor = {
                id: pessoaDoProfile.id,
                nome: pessoaDoProfile.nome
            }
        }

        if (notification === 'NN') {
            item.status = 'NAO_NOTIFICAR'
            item.statusKds = 'NAO_NOTIFICAR'
        }

        recalculateQuantity(item)

        const convertSubItem = convertStepItemToOrderSubItem(pessoaDoProfile)
        item.subItems = convertSubItem

        if (stepNivel && stepNivel === 3) {
            onFinishStepNivel(item)
            handleClose(true)
            return;
        }

        //validar final, percorrer os steps e validar se todos estão com as quantidades corretas
        let orderTemp = Object.assign({}, order)

        if (!orderTemp.iFoodOrder.items)
            orderTemp.iFoodOrder.items = []

        if (newItem) {
            orderTemp.iFoodOrder.items.push(item)
        }

        
        const itemLevelOne = convertLevelZeroForItem()
        itemLevelOne.map(item => {
            orderTemp.iFoodOrder.items.push(item)
            return null;
        })
        
        setOrder(orderTemp)
        
        handleClose(true)        

        if(produto?.observacaoAutomatica){
            setOpenAutoObservation(produto?.observacaoAutomatica)
            setItemStepForObservation(item)
        }
    };

    const handleChangeFilter = (value, activeStep) => {
        if (!activeStep) {
            return
        }

        const activeStepCopy = Object.assign({}, activeStep)
        const itensStep = activeStepCopy.itensOriginal;

        return new Promise((resolve, reject) => {
            const itensStepCopy = Object.assign([], itensStep)
            if (value) {
                const itensFilter = itensStepCopy.filter(item => normalizeText(item.produto.descricao).indexOf(normalizeText(value)) !== -1)
                activeStepCopy.itens = itensFilter
            } else {
                activeStepCopy.itens = itensStepCopy
            }
            resolve(activeStepCopy)
        }).then(activeStepCopy => {
            setActiveStep(activeStepCopy)

            if (activeStepCopy.itens.length > 0) {
                setActiveStep(activeStepCopy)
                console.log("activeStepCopy", activeStepCopy)
            } else {
                searchByCodBar(value, activeStep)
                console.log("activeStep", activeStep)
            }
            //setProductFiltered(products)
        })
    }

    const searchByCodBar = (codigo, activeStep) => {
        // eslint-disable-next-line
        const { codigoBarrasRealProduto } = parseCodigoBarras(codigo, { formato: FORMATO })
        let arrayProducts = []
        const activeStepCopy = Object.assign({}, activeStep)

        if (activeStep?.itensOriginal) {
            activeStep.itensOriginal.map(productFather => {
                const codigoBarrasProduto = productFather.produto.codigoBarras

                if (codigoBarrasProduto === codigoBarrasRealProduto) {
                    arrayProducts.push(productFather)
                }
                return null;
            })
        }

        activeStepCopy.itens = arrayProducts
        setActiveStep(activeStepCopy)
    }

    const handleChangeFilterDelay = React.useMemo(
        () => debounce((value, activeStepCopy) => {
            handleChangeFilter(value, activeStepCopy)
        }, 200),
        // eslint-disable-next-line
        [],
    );

    //Utilizado para aguardar o retorno de adicionar produto
    const addProduct = (product, activeStep) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(addSubItem(product, activeStep));
            }, 100);
        })
    }

    //utilizado para navegação entre os itens e também para adicionar o item ao order
    const handleKeyDownProduct = (event, index, columnRows) => {
        if (event.key === 'Enter') {
            if (productsRef.current && productsRef.current[index] && activeStep.itens[index]) {
                event.preventDefault()
                addProduct(activeStep.itens[index], activeStep).then(() => {
                    productsRef.current[index].focus()
                })
            }
        } else if (event.key === 'ArrowRight') {
            if (productsRef.current) {
                if (productsRef.current[index + 1]) {
                    event.preventDefault()
                    productsRef.current[index + 1].focus()
                }
            }
        } else if (event.key === 'ArrowLeft') {
            if (productsRef.current) {
                if (productsRef.current[index - 1]) {
                    event.preventDefault()
                    productsRef.current[index - 1].focus()
                }
            }
        } else if (event.key === 'ArrowUp') {
            if (productsRef.current) {
                if (productsRef.current[index - columnRows]) {
                    event.preventDefault()
                    productsRef.current[index - columnRows].focus()
                }
            }
        } else if (event.key === 'ArrowDown') {
            if (productsRef.current) {
                if (productsRef.current[index + columnRows]) {
                    event.preventDefault()
                    productsRef.current[index + columnRows].focus()
                }
            }
        }
    }

    //evento de tecla na pesquisa
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {//foca no primeiro item dos itens filtrados
            event.preventDefault()
            handleFocusOnFirtsProduct()
        }
    }

    //seta o foco no primeiro produto
    const handleFocusOnFirtsProduct = () => {
        if (productsRef.current) {
            if (productsRef.current[0]) {
                productsRef.current[0].focus()
            }
        }
    };

    //seta o foco no campo de busca de produto
    useHotkeys('Ctrl+f', (event) => {
        event.preventDefault()
        if (inputSearchRef.current) {
            inputSearchRef.current.focus()
        }
    }, {
        filter: (event) => true
    })

    const Cell = ({ columnIndex, rowIndex, style }) => {

        const columnCount = refColoumnCount.current;
        const index = (rowIndex * columnCount) + columnIndex;
        const itemCardapio = activeStep.itens[index]
        const bgColor = itemCardapio?.produto?.grupoProduto?.cor || backgroundColor;
        const fontColor = theme.palette.getContrastText(bgColor)
        const valorItem = itemCardapio ? currencyFormat(itemCardapio.valor) : ""

        return (
            <div style={{
                ...style,
                left: style.left + GUTTER_SIZE,
                top: style.top + GUTTER_SIZE,
                width: style.width - GUTTER_SIZE,
                height: style.height - GUTTER_SIZE,
                display: 'flex'
            }} >
                {itemCardapio ? (
                    <Box flexGrow={1} p={0.2}>
                        <ButtonBase
                            ref={el => productsRef.current[index] = el}
                            onClick={() => addSubItem(itemCardapio, activeStep)}
                            focusRipple={true}
                            onKeyDown={(event) => handleKeyDownProduct(event, index, columnCount)}
                            style={{
                                height: "100%",
                                width: '100%'
                            }} >
                            <Tooltip disableInteractive title={`${itemCardapio.produto.descricao} - ${valorItem}`}>
                                <Paper
                                    elevation={0}
                                    className={classes.paper}
                                    style={{
                                        background: itemCardapio.produto.grupoProduto.cor || bgColor,
                                        color: fontColor
                                    }} >
                                    <div >
                                        {itemCardapio.produto.descricao}
                                    </div>

                                    <Typography align='right' variant="caption" display="block" style={{
                                        paddingRight: '8px',
                                    }}>
                                        {valorItem}
                                    </Typography>

                                </Paper>
                            </Tooltip>
                        </ButtonBase>
                    </Box>

                ) : (null)}

            </div>
        );
    };

    const handleFinalizarObservacao = (value) => {
        
        const itemsCopy = [...stepsItems]

        for(const i in itemsCopy){
            let itemTempCopy = itemsCopy[i]
            for(const j in itemTempCopy.items){
                if(itemTempCopy.items[j].referenceCode === value.referenceCode){
                    itemsCopy[i].items[j].observations = value.observations
                    break
                }
            }
        } 
        setStepsItems(itemsCopy)
    }

    return (
        <StyledDialog
            maxWidth='md'
            fullWidth
            open={open}
            scroll='paper'
            fullScreen={isMobile}
            onClose={handleClickCancel}
        >

            <DialogTitle onClose={handleClickCancel}>

            </DialogTitle>

            <DialogContent className={classes.dialogPadding}>
                <Grid container className={classes.dialogPaper} spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <Box
                            display="flex"
                            flexDirection='column'
                            height='100%'
                        >
                            <React.Fragment>
                                <Box
                                    display="flex"
                                    flexDirection='column'
                                    alignItems='center'>

                                    <TableContainer>
                                        {steps ?
                                            <Stepper activeStep={activeStepIndex} style={{ padding: "0px 10px 10px 10px" }}>
                                                {steps.map((step) => (

                                                    <Step key={step.id}>
                                                        <StepLabel>{steps.length === 1 ? 'Passo único' : ''}</StepLabel>
                                                    </Step>
                                                ))}

                                            </Stepper>
                                            :
                                            null}
                                    </TableContainer>

                                    <Box alignContent='center'>
                                        {activeStep ?
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Typography align='center' variant='h6' gutterBottom>{activeStep.descricao}</Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography align='center' variant='caption' display="block">{`MÍnimo ${activeStep.minimo} | Máximo ${activeStep.maximo}`} </Typography>
                                                </Grid>

                                            </Grid>
                                            :
                                            null}
                                    </Box>
                                </Box>
                            </React.Fragment >

                            <Box py={1} px={1}>
                                <InputSearch
                                    inputRef={inputSearchRef}
                                    fullWidth
                                    size='small'
                                    autoFocus={true}
                                    onKeyDown={handleKeyDown}
                                    placeholder="procurar pelo nome ou cod. barras (Ctrl+F)"
                                    onFocus={event => event.target.select()}
                                    label="procurar produto pelo nome"
                                    inputProps={{ 'aria-label': 'procurar produto pelo nome' }}
                                    onChange={event => handleChangeFilterDelay(event.target.value, activeStep)}
                                />
                            </Box>

                            <Box flexGrow={1} className={classes.boxProduct} >

                                {activeStep ?
                                    <AutoSizer>
                                        {({ height, width }) => {
                                            const columnWidth = cellRowWidth + GUTTER_SIZE;
                                            const dinamicColumnCount = parseInt(width / (columnWidth));
                                            const dinamicRows = Math.ceil(activeStep.itens?.length / dinamicColumnCount)
                                            refColoumnCount.current = dinamicColumnCount;

                                            const dinamicColumnWidth = parseInt((width - 10) / dinamicColumnCount)

                                            return (
                                                <FixedSizeGrid
                                                    className={classes.scrollBar}
                                                    columnCount={dinamicColumnCount}
                                                    columnWidth={dinamicColumnWidth}
                                                    height={height}
                                                    rowCount={dinamicRows}
                                                    rowHeight={cellRowHeight + GUTTER_SIZE}
                                                    width={width}
                                                >
                                                    {Cell}
                                                </FixedSizeGrid>
                                            )
                                        }}
                                    </AutoSizer>
                                    :
                                    null}
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={5} >
                        <Box
                            display="flex"
                            flexDirection='column'
                            height='100%'>
                            <Box
                                display="flex"
                                flexGrow={1}
                                flexDirection='column'
                                variant="outlined"
                                component={Paper}
                            >
                                <TableContainer className={clsx(classes.resumeOrder, classes.scrollBar)}>
                                    <StepResume
                                        stepsItems={stepsItems} setStepsItems={setStepsItems}
                                        activeStep={activeStep} activeStepIndex={activeStepIndex}
                                        productMenu={productMenu} addSubItem={addSubItem}
                                        stepNivel={stepNivel} />
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions >

                {stepNivel === 3 && (
                    <Chip size='small' color="primary" label="Nível 3" />
                )}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}>

                    <Grid item >
                        <Button
                            disabled={isFirstStep()}
                            onClick={handleBack}
                            size="small"
                            startIcon={<ArrowBackIosIcon />} >
                            Voltar
                        </Button>
                    </Grid>

                    <Grid item >
                        <Button
                            ref={btnConfirmRef}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleNext}
                            endIcon={isLastStep() ? <DoneIcon /> : <ArrowForwardIosIcon />}>
                            {isLastStep() ? 'Finalizar' : 'Próximo'}
                        </Button>
                    </Grid>

                </Grid>




            </DialogActions>

            <ItemAutoObservation 
                open={openAutoObservationStep}
                setOpen={setOpenAutoObservationStep}
                item={subItemForObservation}
                onFinalizar={handleFinalizarObservacao}/>

        </StyledDialog>
    );
}

export default StepModal;